<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <g transform="translate(12.498 12.287)">
      <g transform="translate(0 7.876)">
        <path
          fill="#f59526"
          d="M441.889,664.6h-2.63a1.185,1.185,0,0,0,0,2.369h2.63a1.185,1.185,0,1,0,0-2.369Z"
          transform="translate(-438.074 -664.6)"/>
      </g>
      <g transform="translate(0.01)">
        <path
          fill="#f59526"
          d="M439.559,434.269h1.306l-1.185-1.185v7.876a1.185,1.185,0,0,0,2.369,0v-7.876a1.2,1.2,0,0,0-1.185-1.185h-1.306a1.185,1.185,0,1,0,0,2.369Z"
          transform="translate(-438.374 -431.9)"/>
      </g>
    </g>
    <g transform="translate(13.466 7.927)">
      <path
        fill="#f59526"
        d="M467.381,304.633a.139.139,0,0,1,0,.037c.014-.1.027-.21.044-.315a.374.374,0,0,1-.03.1c.041-.095.081-.19.118-.284a.226.226,0,0,1-.037.061c.061-.081.122-.159.186-.24a.517.517,0,0,1-.074.074c.081-.061.159-.122.24-.186a.58.58,0,0,1-.061.037c.095-.041.19-.081.284-.118a.612.612,0,0,1-.1.03c.1-.014.21-.027.315-.044a.274.274,0,0,1-.074,0c.1.014.21.027.315.044a.374.374,0,0,1-.1-.03c.095.041.19.081.284.118a.226.226,0,0,1-.061-.037c.081.061.159.122.24.186a.517.517,0,0,1-.074-.074c.061.081.122.159.186.24a.583.583,0,0,1-.037-.061c.041.095.081.19.118.284a.614.614,0,0,1-.03-.1c.014.1.027.21.044.315a.274.274,0,0,1,0-.074c-.014.1-.027.21-.044.315a.374.374,0,0,1,.03-.1c-.041.095-.081.19-.118.284a.226.226,0,0,1,.037-.061c-.061.081-.122.159-.186.24a.519.519,0,0,1,.074-.074c-.081.061-.159.122-.24.186a.593.593,0,0,1,.061-.037c-.095.041-.19.081-.284.118a.615.615,0,0,1,.1-.03c-.1.014-.21.027-.315.044a.274.274,0,0,1,.074,0c-.1-.014-.21-.027-.315-.044a.375.375,0,0,1,.1.03c-.095-.041-.19-.081-.284-.118a.227.227,0,0,1,.061.037c-.081-.061-.159-.122-.24-.186a.519.519,0,0,1,.074.074c-.061-.081-.122-.159-.186-.24a.586.586,0,0,1,.037.061c-.041-.095-.081-.19-.118-.284a.613.613,0,0,1,.03.1c-.014-.1-.027-.21-.044-.315,0,.01,0,.024,0,.037a1.238,1.238,0,0,0,.349.836,1.213,1.213,0,0,0,.836.349,1.19,1.19,0,0,0,1.185-1.185,1.6,1.6,0,0,0-.45-1.083,1.533,1.533,0,1,0-.014,2.18,1.6,1.6,0,0,0,.46-1.1,1.178,1.178,0,0,0-.349-.836,1.18,1.18,0,0,0-2.017.836Z"
        transform="translate(-466.683 -303.1)"/>
    </g>
    <g transform="translate(0 0)">
      <path
        fill="#e8ae2a"
        d="M96.449,83.877a14.511,14.511,0,0,1-.125,1.878c.014-.1.027-.21.044-.315a13.887,13.887,0,0,1-.965,3.51c.041-.095.081-.19.118-.284A13.818,13.818,0,0,1,93.748,91.7c.061-.081.122-.159.186-.24A14.115,14.115,0,0,1,91.4,93.99c.081-.061.159-.122.24-.186a13.976,13.976,0,0,1-3.033,1.774c.095-.041.19-.081.284-.118a13.887,13.887,0,0,1-3.51.965c.1-.014.21-.027.315-.044a14.246,14.246,0,0,1-3.757,0c.1.014.21.027.315.044a13.886,13.886,0,0,1-3.51-.965c.095.041.19.081.284.118A13.818,13.818,0,0,1,76,93.8c.081.061.159.122.24.186a14.115,14.115,0,0,1-2.532-2.532c.061.081.122.159.186.24a13.977,13.977,0,0,1-1.774-3.033c.041.095.081.19.118.284a13.887,13.887,0,0,1-.965-3.51c.014.1.027.21.044.315a14.246,14.246,0,0,1,0-3.757c-.014.1-.027.21-.044.315a13.887,13.887,0,0,1,.965-3.51c-.041.095-.081.19-.118.284a13.818,13.818,0,0,1,1.774-3.033c-.061.081-.122.159-.186.24a14.115,14.115,0,0,1,2.532-2.532c-.081.061-.159.122-.24.186a13.976,13.976,0,0,1,3.033-1.774c-.095.041-.19.081-.284.118a13.886,13.886,0,0,1,3.51-.965c-.1.014-.21.027-.315.044a14.246,14.246,0,0,1,3.757,0c-.1-.014-.21-.027-.315-.044a13.886,13.886,0,0,1,3.51.965c-.095-.041-.19-.081-.284-.118a13.818,13.818,0,0,1,3.033,1.773c-.081-.061-.159-.122-.24-.186A14.115,14.115,0,0,1,93.935,76.3c-.061-.081-.122-.159-.186-.24a13.977,13.977,0,0,1,1.773,3.033c-.041-.095-.081-.19-.118-.284a13.886,13.886,0,0,1,.965,3.51c-.014-.1-.027-.21-.044-.315a15.136,15.136,0,0,1,.125,1.878,1.185,1.185,0,1,0,2.369,0,14.959,14.959,0,0,0-2.735-8.627,15.759,15.759,0,0,0-3.263-3.361A14.857,14.857,0,0,0,88.9,69.763a14.972,14.972,0,0,0-16.588,4.512,15.679,15.679,0,0,0-2.423,4.068,14.792,14.792,0,0,0-1.022,4.447A14.983,14.983,0,0,0,77.547,97.5a15.946,15.946,0,0,0,2.234.829,15.694,15.694,0,0,0,2.437.457A14.98,14.98,0,0,0,98.615,86.3a16.6,16.6,0,0,0,.2-2.423,1.185,1.185,0,0,0-2.369,0Z"
        transform="translate(-68.819 -68.879)"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

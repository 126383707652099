<template>
  <article class="dashboard_form generic_form">
    <div class="generic_form__section">
      <v-checkbox
      v-model="dashboard.users"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Usuarios</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="dashboard.offers"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Ofertas</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="dashboard.rewards"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Rewards</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="dashboard.access"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Accesos</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  data(){
    return{
      dashboard:{
        users: false,
        offers: false,
        rewards: false,
        access: false
      }
    }
  },
  mounted(){
    this.dashboard = this.getCompanyDashboard()
  },
  beforeDestroy(){
    this.updateCompanyDashboard(this.dashboard)
    console.log(this.getCompanyDashboard());
  }
}
</script>

<style lang="css">
</style>

<template>
  <article class="generic_form">
    <div class="generic_form__section">
      <v-checkbox
      v-model="activation_api_connection"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Activar</p>
      </div>
    </div>
    <!-- <div class="generic_form__section">
      <v-checkbox
      v-model="api_connections.crm"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">CRM</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="api_connections.end_points"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">End Points especificos</p>
      </div>
    </div> -->
  </article>
</template>

<script>
export default {
  data(){
    return{
      activation_api_connection: false,
      api_connections:{
        crm: false,
        end_points: false
      }
    }
  },
  watch:{
    activation_api_connection(){
      if(this.activation_api_connection){
        this.api_connections.crm = true
        this.api_connections.end_points = true
      }else{
        this.api_connections.crm = false
        this.api_connections.end_points = false
      }
    }
  },
  mounted(){
    this.api_connections = this.getCompanyApiConnections()
    if(this.api_connections.crm == true ||
       this.api_connections.end_points == true){
      this.activation_api_connection = true
    }else{
      this.activation_api_connection = false
    }
  },
  beforeDestroy(){
    this.updateCompanyApiConnections(this.api_connections)
    console.log(this.getCompanyApiConnections());
  }
}
</script>

<style lang="css">
</style>

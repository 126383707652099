<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="28"
    viewBox="0 0 34 28">
    <g transform="translate(0 -43.894)">
      <path
        fill="#21cbf3"
        d="M331,192.635l8.849-5.8-8.849-5.8Zm1.992-7.921,3.237,2.122-3.237,2.122Z"
        transform="translate(-309.02 -128.091)"/>
      <path
        fill="#78a2c6"
        d="M17.19,47.78A21.291,21.291,0,0,0,4.98,43.894h-1v3.352A37.9,37.9,0,0,0,0,48.071V71.894a32.9,32.9,0,0,1,16.758-.308l.242.06.242-.06A32.848,32.848,0,0,1,34,71.894V48.071A34.7,34.7,0,0,0,17.19,47.78Zm-15.2,21.6V49.627c.659-.147,1.325-.273,1.992-.378V67.656h1A19.219,19.219,0,0,1,11.3,68.738a34.334,34.334,0,0,0-9.311.644ZM16,68.789A21.208,21.208,0,0,0,5.977,65.7V45.9A19.224,19.224,0,0,1,16,49.374Zm16,.593a34.344,34.344,0,0,0-14.012,0V49.627a32.318,32.318,0,0,1,14.012,0Z"
        transform="translate(0 0)"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

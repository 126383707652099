<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <g transform="translate(-7.804)">
      <g transform="translate(7.804)">
        <path
          fill="#26cca0"
          d="M35.143,3.254q-.1,0-.191,0h-2.4V1.265C32.552.47,31.765,0,30.94,0H28.728A1.273,1.273,0,0,0,27.3,1.265V3.253h-9V1.265A1.273,1.273,0,0,0,16.88,0H14.668c-.825,0-1.612.47-1.612,1.265V3.253h-2.4A2.733,2.733,0,0,0,7.8,5.852q0,.092,0,.184V26.928A2.984,2.984,0,0,0,10.656,30h24.3a2.984,2.984,0,0,0,2.85-3.072V6.036A2.716,2.716,0,0,0,35.143,3.254ZM28.728,1.446h2.325V5.422H28.728Zm-14.06,0H16.8V5.422H14.668ZM36.3,26.928a1.517,1.517,0,0,1-1.35,1.626h-24.3a1.517,1.517,0,0,1-1.35-1.626V11.566h27V26.928Zm0-20.892v4.084h-27V6.036A1.243,1.243,0,0,1,10.46,4.7a1.323,1.323,0,0,1,.2,0h2.4v.8a1.524,1.524,0,0,0,1.612,1.373H16.88a1.366,1.366,0,0,0,1.425-1.3q0-.037,0-.073V4.7h9v.8a1.36,1.36,0,0,0,1.349,1.373H30.94a1.524,1.524,0,0,0,1.612-1.373V4.7h2.4A1.259,1.259,0,0,1,36.3,5.848,1.186,1.186,0,0,1,36.3,6.036Z"
          transform="translate(-7.804)"/>
        <path
          fill="#00a277"
          d="M120.7,202.55l-.7,3.773a.735.735,0,0,0,.078.473.791.791,0,0,0,1.051.319l3.5-1.773,3.5,1.773.35.075a.755.755,0,0,0,.467-.151.744.744,0,0,0,.311-.717l-.7-3.773,2.841-2.641a.743.743,0,0,0-.428-1.283l-3.892-.566-1.751-3.433a.767.767,0,0,0-.361-.35.79.79,0,0,0-1.04.35l-1.751,3.433-3.892.566a.743.743,0,0,0-.428,1.283Zm2.141-3.056a.7.7,0,0,0,.545-.415l1.245-2.415,1.245,2.415a.7.7,0,0,0,.545.415l2.763.377L127.2,201.72a.778.778,0,0,0-.234.679l.467,2.679-2.452-1.283-.35-.075-.35.075-2.452,1.283.467-2.679a.777.777,0,0,0-.234-.679l-1.985-1.849Z"
          transform="translate(-109.628 -181.201)"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

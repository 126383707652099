<template>
  <article class="generic_form">
    <div class="generic_form__section">
      <v-checkbox
      v-model="messages.notifications"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Notificaciones</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="messages.segment_mails"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Correos segmentados</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="messages.whatsapp"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">WhatsApp</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  data(){
    return{
      messages:{
        notifications: false,
        segment_mails: false,
        whatsapp: false
      }
    }
  },
  mounted(){
    this.messages = this.getCompanyMessages()
  },
  beforeDestroy(){
    this.updateCompanyMessages(this.messages)
    console.log(this.getCompanyMessages());
  }
}
</script>

<style lang="css">
</style>

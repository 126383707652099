<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="28"
    viewBox="0 0 30 28">
    <g transform="translate(0 -17)">
      <path
        fill="#8642cb"
        d="M29.121,23.092h-6.68V17.879A.879.879,0,0,0,21.563,17H.879A.879.879,0,0,0,0,17.879V40.607A4.4,4.4,0,0,0,4.395,45H25.605A4.4,4.4,0,0,0,30,40.607V23.971A.879.879,0,0,0,29.121,23.092ZM4.395,43.243a2.639,2.639,0,0,1-2.637-2.636V18.757H20.684c0,23.224-.011,21.933.02,22.264a4.379,4.379,0,0,0,.861,2.221Zm23.848-2.636a2.639,2.639,0,0,1-2.637,2.636h-.527a2.639,2.639,0,0,1-2.637-2.636V24.849h5.8Z"
        transform="translate(0 0)"/>
      <path
        fill="#5242cb"
        d="M79,89H65.879a.879.879,0,1,0,0,1.757H79A.879.879,0,1,0,79,89Z"
        transform="translate(-61.191 -67.782)"/>
      <path
        fill="#5242cb"
        d="M79,153H65.879a.879.879,0,1,0,0,1.757H79A.879.879,0,1,0,79,153Z"
        transform="translate(-61.191 -128.033)"/>
      <path
        fill="#5242cb"
        d="M79,393H65.879a.879.879,0,1,0,0,1.757H79A.879.879,0,1,0,79,393Z"
        transform="translate(-61.191 -353.975)"/>
      <path
        fill="#5242cb"
        d="M184.441,217h-6.562a.879.879,0,0,0-.879.879v6.561a.879.879,0,0,0,.879.879h6.563a.879.879,0,0,0,.879-.879v-6.561A.879.879,0,0,0,184.441,217Zm-.879,6.561h-4.8v-4.8h4.8Z"
        transform="translate(-166.629 -188.285)"/>
      <path
        fill="#5242cb"
        d="M65.879,242.757h2.812a.879.879,0,1,0,0-1.757H65.879a.879.879,0,1,0,0,1.757Z"
        transform="translate(-61.191 -210.879)"/>
      <path
        fill="#5242cb"
        d="M65.879,306.757h2.812a.879.879,0,1,0,0-1.757H65.879a.879.879,0,1,0,0,1.757Z"
        transform="translate(-61.191 -271.13)"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

const state = {
  user_id: '',
  name_user: '',
  email_user: '',
  user_type: '',
  is_login: false,
  user_id_encrypt: null,
  language: '',
  show_drawer: true,
  show_modal: false,
  device_id: '',
  user_token: '',
  user_sections:{
    offers: false,
    rewards: false,
    favorites: false,
    invitations: false,
    room_bookings: false,
    locations: true,
    personal_trainer: false,
    news: false,
    events: false,
    company_connections: false,
    us: true,
    contact_us: true,
    inside_request: false,
    work_with_us: true,
    directory: true
  },
  company:{
    user_name: '',
    description:"",
    document_number: "",
    email: "",
    company_name: "",
    address: "",
    identify_number: "",
    contact_email:'',
    dashboard:{
      users: false,
      offers: false,
      rewards: false,
      access: false
    },
    offers:{
      redemption_form:"",
      favorites: true
    },
    rewards:{
      user_characteristics: false,
      segments: false,
      rewards: false
    },
    access:{
      general_access:'',
      without_internet_access: false,
      keyboard_access: false,
      dynamic_invitations: false,
      room_bookings: '',
      courtesies: false,
      covenants: false,
      reports: false,
      alerts: false,
      counter: false,
      user_access: false,
      embed_qr: false,
      active_booking_size: null,
      bookin_limit_per_day: null,
      single_access_per_booking: false,
      virtual_guard: false
    },
    locations:{
      offers_and_rewards: false,
      sublocations: {
        time_out: true,
        bookings: true
      },
      holidays: true
    },
    personal_trainer:{
      personal_trainer: false
    },
    messages:{
      notifications:false,
      segment_mails: false,
      whatsapp: false
    },
    news:{
      general_news: false,
      video_news: false,
      twits_news:false
    },
    events:{
      tickets: false
    },
    company_connections:{
      company_connections: false
    },
    client_information: {
      us: false,
      contact_us: false,
      work_with_us: false,
      directory: false
    },
    personalize:{
      web_page: false,
      mobile_app: false,
      colors: {
        principal: '',
        secundary:''
      },
      app_logo: '',
      principal_sections: [],
      secondary_sections: [],
      personalize_mails: false
    },
    inside_request:{
      payment_certificate: false,
      work_certificate: false,
      contribution_certificate: false,
      payroll_credit: false
    },
    api_connections:{
      crm: false,
      end_points: false
    }
  }
}

const getters = {
  getUserId(state) {
      return state.user_id
  },
  getUserType(state) {
      return state.user_type
  },
  getEmailUser (state) {
      return state.email_user
  },
  getNameUser (state) {
      return state.name_user
  },
  getIsLogin(state) {
        return state.is_login
  },
  getUserIdEncrypt(state){
      return state.user_id_encrypt
  },
  getUserToken (state) { return state.user_token },
  getDeviceId (state) { return state.device_id },
  getLanguage (state) { return state.language },
  getShowDrawer (state) { return state.show_drawer },
  getShowModal (state) { return state.show_modal },
  getCompany (state) { return state.company },
  getCompanyUserName (state) { return state.company.user_name },
  getCompanyDescription (state) { return state.company.description },
  getCompanyDocumentNumber (state) { return state.company.document_number },
  getCompanyEmail (state) { return state.company.email },
  getCompanyCompanyName (state) { return state.company.company_name },
  getCompanyAddress (state) { return state.company.address },
  getCompanyIdentifyNumber (state) { return state.company.identify_number },
  getCompanyContactEmail (state) { return state.company.contanct_email },
  getCompanyDashboard (state) { return state.company.dashboard },
  getCompanyOffers (state) { return state.company.offers },
  getCompanyRewards (state) { return state.company.rewards },
  getCompanyAccess (state) { return state.company.access },
  getCompanyLocations (state) { return state.company.locations },
  getCompanyPersonalTrainer (state) { return state.company.personal_trainer },
  getCompanyMessages (state) { return state.company.messages },
  getCompanyNews (state) { return state.company.news },
  getCompanyEvents (state) { return state.company.events },
  getCompanyCompanyConnections (state) { return state.company.company_connections },
  getCompanyClientInformation (state) { return state.company.client_information },
  getCompanyPersonalize (state) { return state.company.personalize },
  getCompanyInsideRequest (state) { return state.company.inside_request },
  getCompanyApiConnections (state) { return state.company.api_connections },
  getValidationOffers (state) {return state.user_sections.offers},
  getValidationRewards (state) {return state.user_sections.rewards},
  getValidationFavorites (state) {return state.user_sections.favorites},
  getValidationInvitations (state) {return state.user_sections.invitations},
  getValidationRoomBookings (state) {return state.user_sections.room_bookings},
  getValidationLocations (state) {return state.user_sections.locations},
  getValidationPersonalTrainer (state) {return state.user_sections.personal_trainer},
  getValidationNews (state) {return state.user_sections.news},
  getValidationEvents (state) {return state.user_sections.events},
  getValidationCompanyConnections (state) {return state.user_sections.company_connections},
  getValidationUs (state) {return state.user_sections.us},
  getValidationContactUs (state) {return state.user_sections.contact_us},
  getValidationInsideRequest (state) {return state.user_sections.inside_request},
  getValidationWorkWithUs (state) {return state.user_sections.work_with_us},
  getValidationDirectory (state) {return state.user_sections.directory},
}

const mutations = {
  setUserId(state, payload) {
      state.user_id = payload
  },
  setUserType(state, payload) {
      state.user_type = payload
  },
  setEmailUser: function (state, payload) {
      state.email_user = payload
      sessionStorage.setItem('email_user',payload)
  },
  setNameUser: function (state, payload) {
      state.name_user = payload
      sessionStorage.setItem('name_user',payload)
  },
  setIsLogin: function(state, payload) {
      state.is_login = payload
  },
  setUserIdEncrypt: function (state, payload) {
      state.user_id_encrypt = payload
      sessionStorage.setItem('user_id_encrypt',payload)
  },
  setLanguage(state, payload) { state.language = payload },
  setShowDrawer(state, payload) { state.show_drawer = payload },
  setShowModal(state, payload) { state.show_modal = payload },
  setCompany(state, payload) { state.company = payload },
  setUserToken(state, payload) { state.user_token = payload },
  setDeviceId(state, payload) { state.device_id = payload },
  setCompanyUserName(state, payload) { state.company.user_name = payload },
  setCompanyDescription(state, payload) { state.company.description = payload },
  setCompanyDocumentNumber(state, payload) { state.company.document_number = payload },
  setCompanyEmail(state, payload) { state.company.email = payload },
  setCompanyCompanyName(state, payload) { state.company.company_name = payload },
  setCompanyAddress(state, payload) { state.company.address = payload },
  setCompanyIdentifyNumber(state, payload) { state.company.identify_number = payload },
  setCompanyContactEmail(state, payload) { state.company.contact_email = payload },
  setCompanyDashboard(state, payload) { state.company.dashboard = payload },
  setCompanyOffers(state, payload) { state.company.offers = payload },
  setCompanyRewards(state, payload) { state.company.rewards = payload },
  setCompanyAccess(state, payload) { state.company.access = payload },
  setCompanyLocations(state, payload) { state.company.locations = payload },
  setCompanyPersonalTrainer(state, payload) { state.company.personal_trainer = payload },
  setCompanyMessages(state, payload) { state.company.messages = payload },
  setCompanyNews(state, payload) { state.company.news = payload },
  setCompanyEvents(state, payload) { state.company.events = payload },
  setCompanyCompanyConnections(state, payload) { state.company.company_connections = payload },
  setCompanyClientInformation(state, payload) { state.company.client_information = payload },
  setCompanyPersonalize(state, payload) { state.company.personalize = payload },
  setCompanyInsideRequest(state, payload) { state.company.inside_request = payload },
  setCompanyApiConnections(state, payload) { state.company.api_connections = payload },
  setValidationOffers (state, payload) { state.user_sections.offers = payload},
  setValidationRewards (state, payload) { state.user_sections.rewards = payload},
  setValidationFavorites (state, payload) { state.user_sections.favorites = payload},
  setValidationInvitations (state, payload) { state.user_sections.invitations = payload},
  setValidationRoomBookings (state, payload) { state.user_sections.room_bookings = payload},
  setValidationLocations (state, payload) { state.user_sections.locations = payload},
  setValidationPersonalTrainer (state, payload) { state.user_sections.personal_trainer = payload},
  setValidationNews (state, payload) { state.user_sections.news = payload},
  setValidationEvents (state, payload) { state.user_sections.events = payload},
  setValidationCompanyConnections (state, payload) { state.user_sections.company_connections = payload},
  setValidationUs (state, payload) { state.user_sections.us = payload},
  setValidationContactUs (state, payload) { state.user_sections.contact_us = payload},
  setValidationInsideRequest (state, payload) { state.user_sections.inside_request = payload},
  setValidationWorkWithUs (state, payload) { state.user_sections.work_with_us = payload},
  setValidationDirectory (state, payload) { state.user_sections.directory = payload},
}

const actions = {
  updateUserId({ commit }, payload) {
      commit('setUserId', payload)
  },
  updateUserType({ commit }, payload) {
      commit('setUserType', payload)
  },
  updateEmailUser: function ({commit}, payload) {
      commit('setEmailUser', payload)
  },
  updateNameUser: function ({commit}, payload) {
      commit('setNameUser', payload)
  },
  updateIsLogin: function({ commit }, payload) {
      commit('setIsLogin', payload)
  },
  updateUserIdEncrypt: function ({commit}, payload) {
      commit('setUserIdEncrypt', payload)
  },
  updateUserToken({commit}, payload) { commit('setUserToken', payload) },
  updateLanguage({commit}, payload) { commit('setLanguage', payload) },
  updateShowDrawer({commit}, payload) { commit('setShowDrawer', payload) },
  updateShowModal({commit}, payload) { commit('setShowModal', payload) },
  updateCompany({commit}, payload) { commit('setCompany', payload) },
  updateDeviceId({commit}, payload) { commit('setDeviceId', payload)  },
  updateCompanyUserName({commit}, payload) { commit('setCompanyUserName', payload)  },
  updateCompanyDescription({commit}, payload) { commit('setCompanyDescription', payload)  },
  updateCompanyDocumentNumber({commit}, payload) { commit('setCompanyDocumentNumber', payload)  },
  updateCompanyEmail({commit}, payload) { commit('setCompanyEmail', payload)  },
  updateCompanyCompanyName({commit}, payload) { commit('setCompanyCompanyName', payload)  },
  updateCompanyAddress({commit}, payload) { commit('setCompanyAddress', payload)  },
  updateCompanyIdentifyNumber({commit}, payload) { commit('setCompanyIdentifyNumber', payload)  },
  updateCompanyContactEmail({commit}, payload) { commit('setCompanyContactEmail', payload)  },
  updateCompanyDashboard({commit}, payload) { commit('setCompanyDashboard', payload)  },
  updateCompanyOffers({commit}, payload) { commit('setCompanyOffers', payload)  },
  updateCompanyRewards({commit}, payload) { commit('setCompanyRewards', payload)  },
  updateCompanyAccess({commit}, payload) { commit('setCompanyAccess', payload)  },
  updateCompanyLocations({commit}, payload) { commit('setCompanyLocations', payload)  },
  updateCompanyPersonalTrainer({commit}, payload) { commit('setCompanyPersonalTrainer', payload)  },
  updateCompanyMessages({commit}, payload) { commit('setCompanyMessages', payload)  },
  updateCompanyNews({commit}, payload) { commit('setCompanyNews', payload)  },
  updateCompanyEvents({commit}, payload) { commit('setCompanyEvents', payload)  },
  updateCompanyCompanyConnections({commit}, payload) { commit('setCompanyCompanyConnections', payload)  },
  updateCompanyClientInformation({commit}, payload) { commit('setCompanyClientInformation', payload)  },
  updateCompanyPersonalize({commit}, payload) { commit('setCompanyPersonalize', payload)  },
  updateCompanyInsideRequest({commit}, payload) { commit('setCompanyInsideRequest', payload)  },
  updateCompanyApiConnections({commit}, payload) { commit('setCompanyApiConnections', payload)  },
  updateValidationOffers ({commit}, payload) {commit('setValidationOffers', payload)},
  updateValidationRewards ({commit}, payload) {commit('setValidationRewards', payload)},
  updateValidationFavorites ({commit}, payload) {commit('setValidationFavorites', payload)},
  updateValidationInvitations ({commit}, payload) {commit('setValidationInvitations', payload)},
  updateValidationRoomBookings ({commit}, payload) {commit('setValidationRoomBookings', payload)},
  updateValidationLocations ({commit}, payload) {commit('setValidationLocations', payload)},
  updateValidationPersonalTrainer ({commit}, payload) {commit('setValidationPersonalTrainer', payload)},
  updateValidationNews ({commit}, payload) {commit('setValidationNews', payload)},
  updateValidationEvents ({commit}, payload) {commit('setValidationEvents', payload)},
  updateValidationCompanyConnections ({commit}, payload) {commit('setValidationCompanyConnections', payload)},
  updateValidationUs ({commit}, payload) {commit('setValidationUs', payload)},
  updateValidationContactUs ({commit}, payload) {commit('setValidationContactUs', payload)},
  updateValidationInsideRequest ({commit}, payload) {commit('setValidationInsideRequest', payload)},
  updateValidationWorkWithUs ({commit}, payload) {commit('setValidationWorkWithUs', payload)},
  updateValidationDirectory ({commit}, payload) {commit('setValidationDirectory', payload)},

}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}

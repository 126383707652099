<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="60"
    height="60"
    viewBox="0 0 60 60">
    <defs>
      <clipPath id="a">
        <circle
          fill="#f4f4f4"
          stroke="#c3c7d9"
          cx="29"
          cy="29"
          r="29"
          transform="translate(749.17 335.439)"/>
      </clipPath>
    </defs>
    <g transform="translate(-749 -335)">
      <g
        fill="#f4f4f4"
        stroke="#c3c7d9"
        transform="translate(749 335)">
        <circle
          stroke="none"
          cx="30"
          cy="30"
          r="30"/>
        <circle
          fill="none"
          cx="30"
          cy="30"
          r="29.5"/>
      </g>
      <g
        clip-path="url(#a)"
        transform="translate(0.83 0.561)">
        <path
          fill="#abb0c4" d="M53.313,51.882c-.916-4.026-6.143-5.988-7.949-6.621-1.984-.7-4.808-.861-6.626-1.266a6.747,6.747,0,0,1-3.065-1.405c-.509-.608-.2-6.241-.2-6.241a18.748,18.748,0,0,0,1.45-2.747,35.288,35.288,0,0,0,1.068-4.8s1.043,0,1.412-1.823c.394-1.987,1.017-2.76.941-4.241-.076-1.456-.878-1.418-.878-1.418a22.1,22.1,0,0,0,.865-6.494c.114-5.2-3.981-10.33-11.4-10.33-7.517,0-11.523,5.127-11.409,10.33a23.05,23.05,0,0,0,.852,6.494s-.8-.038-.878,1.418c-.076,1.481.547,2.253.941,4.241C18.8,28.8,19.851,28.8,19.851,28.8a35.288,35.288,0,0,0,1.068,4.8,18.748,18.748,0,0,0,1.45,2.747s.305,5.633-.2,6.241A6.747,6.747,0,0,1,19.1,44c-1.819.405-4.642.57-6.626,1.266-1.806.633-7.033,2.6-7.949,6.621a1.013,1.013,0,0,0,1,1.228H52.321A1.011,1.011,0,0,0,53.313,51.882Z"
          transform="translate(749.659 340.768)"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

<template>
  <article class="generic_form">
    <div class="generic_form__section">
      <v-checkbox
      v-model="company_connections.company_connections"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Conectar empresas</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  data(){
    return{
      company_connections:{
        company_connections: false
      }
    }
  },
  mounted(){
    this.company_connections = this.getCompanyCompanyConnections()
  },
  beforeDestroy(){
    this.updateCompanyCompanyConnections(this.company_connections)
    this.updateValidationCompanyConnections(this.company_connections.company_connections)
    console.log(this.getCompanyCompanyConnections());
  }
}
</script>

<style lang="css">
</style>

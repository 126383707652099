// import Vue from 'vue'
import Vue from 'vue'
// Menu
import Navigation from '@/components/shared/Navigation.vue'

// Form components
import ModalForm from '@/components/shared/ModalForm.vue'
import AccessForm from '@/components/forms/AccessForm.vue'
import ApiConnectionsForm from '@/components/forms/ApiConnectionsForm.vue'
import ClientInformationForm from '@/components/forms/ClientInformationForm.vue'
import CompanyConnectionsForm from '@/components/forms/CompanyConnectionsForm.vue'
import DashboardForm from '@/components/forms/DashboardForm.vue'
import EventsForm from '@/components/forms/EventsForm.vue'
import InsideRequestForm from '@/components/forms/InsideRequestForm.vue'
import LocationForm from '@/components/forms/LocationForm.vue'
import MessagesForm from '@/components/forms/MessagesForm.vue'
import NewsForm from '@/components/forms/NewsForm.vue'
import OfferForm from '@/components/forms/OfferForm.vue'
import PersonalizeForm from '@/components/forms/PersonalizeForm.vue'
import PersonalTrainerForm from '@/components/forms/PersonalTrainerForm.vue'
import RewardsForm from '@/components/forms/RewardsForm.vue'

// Icons
import Logo from '@/components/shared/Logo.vue'
import CompanyLogo from '@/components/shared/CompanyLogo.vue'
import Upload from '@/components/shared/Upload.vue'
import ReverseArrow from '@/components/shared/ReverseArrow.vue'
import DashboardIcon from '@/components/shared/icons/DashboardIcon.vue'
import OffersIcon from '@/components/shared/icons/OffersIcon.vue'
import RewardsIcon from '@/components/shared/icons/RewardsIcon.vue'
import AccessIcon from '@/components/shared/icons/AccessIcon.vue'
import LocationIcon from '@/components/shared/icons/LocationIcon.vue'
import PersonalTrainerIcon from '@/components/shared/icons/PersonalTrainerIcon.vue'
import MessageIcon from '@/components/shared/icons/MessageIcon.vue'
import NewsIcon from '@/components/shared/icons/NewsIcon.vue'
import EventsIcon from '@/components/shared/icons/EventsIcon.vue'
import ClientConnectionsIcon from '@/components/shared/icons/ClientConnectionsIcon.vue'
import InformationIcon from '@/components/shared/icons/InformationIcon.vue'
import PersonalizeIcon from '@/components/shared/icons/PersonalizeIcon.vue'
import InsideRequestIcon from '@/components/shared/icons/InsideRequestIcon.vue'
import ApiConnectionIcon from '@/components/shared/icons/ApiConnectionIcon.vue'

import Login from '@/components/shared/Login.vue'

Vue.component('app-modal', ModalForm);
Vue.component('access-form', AccessForm);
Vue.component('api-connections-form', ApiConnectionsForm);
Vue.component('client-information-form', ClientInformationForm);
Vue.component('company-connections-form', CompanyConnectionsForm);
Vue.component('dashboard-form', DashboardForm);
Vue.component('events-form', EventsForm);
Vue.component('inside-request-form', InsideRequestForm);
Vue.component('location-form', LocationForm);
Vue.component('messages-form', MessagesForm);
Vue.component('news-form', NewsForm);
Vue.component('offer-form', OfferForm);
Vue.component('personalize-form', PersonalizeForm);
Vue.component('personal-trainer-form', PersonalTrainerForm);
Vue.component('rewards-form', RewardsForm);

Vue.component('navigation', Navigation);
Vue.component('app-logo', Logo);
Vue.component('company-logo', CompanyLogo);
Vue.component('upload-icon', Upload);
Vue.component('reverse-arrow', ReverseArrow);
Vue.component('dashboard-icon', DashboardIcon);
Vue.component('offers-icon', OffersIcon);
Vue.component('rewards-icon', RewardsIcon);
Vue.component('access-icon', AccessIcon);
Vue.component('location-icon', LocationIcon);
Vue.component('personal-trainer-icon', PersonalTrainerIcon);
Vue.component('message-icon', MessageIcon);
Vue.component('news-icon', NewsIcon);
Vue.component('events-icon', EventsIcon);
Vue.component('client-connections-icon', ClientConnectionsIcon);
Vue.component('information-icon', InformationIcon);
Vue.component('personalize-icon', PersonalizeIcon);
Vue.component('inside-request-icon', InsideRequestIcon);
Vue.component('api-connection-icon', ApiConnectionIcon);
Vue.component('login', Login);

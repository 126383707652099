<template>
  <article class="client">
    <section class="client__header">
      <p>Edición de cliente</p>
    </section>
    <section class="client__container">
      <ValidationObserver v-slot="{ invalid }">

      <div class="client__title">
        <p>Información del cliente</p>
        <span>Campos obligatorios*</span>
      </div>
      <section class="client__form">
        <section class="client__form--section">
          <div class="client__form--field">
            <span>Correo electrónico*</span>
            <validation-provider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
              class="validation_field">
              <input
                type="text"
                v-model="company.email"
                placeholder="Ingrese su correo electrónico" />
              <span>{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div
            class="client__form--field client__form--password"
            v-if="prevent_edit">
            <button
              class="client__button client__button--edit client__button--principal"
              @click="prevent_edit = false">
              Editar contraseña administrador
            </button>
          </div>
          <div v-else>
            <div class="client__form--field">
              <span>Contraseña*</span>
              <ValidationProvider
                rules="required|password:@confirm"
                v-slot="{ errors }"
                class="validation_field">
                <input
                  type="password"
                  v-model="company.password"
                  placeholder="Contraseña del administrador"/>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="client__form--field">
              <span>Confirmar contraseña*</span>
              <ValidationProvider
                name="confirm"
                rules="required"
                class="validation_field"
                v-slot="{ errors }">
                <input
                  type="password"
                  v-model="company.password_confirmation"
                  placeholder="Confirmar contraseña del administrador"/>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="client__form--field">
            <span>Correo de contacto*</span>
            <validation-provider
              name="contact_email"
              rules="required|email"
              v-slot="{ errors }"
              class="validation_field">
              <input
                type="text"
                v-model="company.contact_email"
                placeholder="Ingrese su correo electrónico" />
              <span>{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="client__logo_input client__form--field">
            <span><company-logo></company-logo></span>
            <div class="client__logo_input--input">
              <upload-icon></upload-icon>
              <label
                for="companyLogo"
                style="">Cargar logo del cliente</label>
              <input
                id="companyLogo"
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
                @change="onFileChange"
                name="logo">
            </div>
          </div>
        </section>
        <section class="client__form--section">
          <div class="client__form--field">
            <span>Empresa*</span>
            <validation-provider
              name="company_name"
              rules="required"
              v-slot="{ errors }"
              class="validation_field">
              <input
                type="text"
                v-model="company.company_name"
                placeholder="Nombre del responsable" />
              <span>{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="client__form--field">
            <span>NIT*</span>
            <validation-provider
              name="identify_number"
              rules="required"
              v-slot="{ errors }"
              class="validation_field">
              <input
                type="text"
                v-model="company.identify_number"
                placeholder="Ingrese el NIT de la empresa" />
              <span>{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="client__form--field">
            <span>Dirección*</span>
            <validation-provider
              name="address"
              rules="required"
              v-slot="{ errors }"
              class="validation_field">
              <input
                type="text"
                v-model="company.address"
                placeholder="Dirección de la empresa" />
              <span>{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="client__form--field">
            <span>Descripción</span>
            <textarea
              v-model="company.description"
              placeholder="Descipción corta de la empresa"></textarea>
          </div>
        </section>
      </section>
      <div class="client__title">
        <p>Activar secciones para cliente</p>
        <span>Secciones obligatorias*</span>
      </div>
      <section
        class="client__sections"
        v-if="charged">
        <div
          class="client__service_section"
          v-for="(filter_section, index) in filter_sections"
          :key="'fs'+index">
          <section
            class="client__service_section--section client__service"
            :class="{'active': service_selected == service.service_id}"
            v-for="service in filter_section"
            :key="service.service_id">
            <div class="client__service--container">
              <component
                :is="service.component"
                :is_instance="true"
                class="client__service--icon"></component>
              <p class="client__service--title">{{service.title}}</p>
              <div
                @click="select_service(service.service_id)"
                class="client__service--arrow"
                :class="{'client__service--active': service_selected != service.service_id}">
                <reverse-arrow></reverse-arrow>
              </div>
            </div>
            <transition name="fade">
              <component
                :is="service.form"
                :is_instance="true"
                v-if="service_selected == service.service_id"></component>
            </transition>
          </section>
        </div>
      </section>
      <section class="client__buttons">
        <div
          class="client__button"
          @click="$router.push({name: 'root'})">
          Cancelar
        </div>
        <button
          class="client__button client__button--principal"
          :disabled="invalid"
          @click="edit_client()">
          Editar cliente
        </button>
      </section>
      </ValidationObserver>
    </section>
  </article>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
// import { validate } from 'vee-validate';
extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
});
extend('email', {
  ...email,
  message: 'Este email no es valido'
});
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Las contraseñas no coinciden'
});

export default {
  data(){
    return{
      service_selected: null,
      filter_sections: [],
      charged: false,
      url: '',
      app_logo:'',
      prevent_edit: false,
      company:{
        user_name:'',
        description:'',
        document_number:'',
        email: '',
        company_name:'',
        address:'',
        identify_number:'',
        contact_email: '',
        password:"",
        password_confirmation:""
      },
      services:[
        {
          title: "Dashboard",
          component: "dashboard-icon",
          form:"dashboard-form",
          service_id: 1
        },
        {
          title: "Ofertas",
          component: "offers-icon",
          form:"offer-form",
          service_id: 2
        },
        {
          title: "Fidelización",
          component: "rewards-icon",
          form:"rewards-form",
          service_id: 3
        },
        {
          title: "Accesos",
          component: "access-icon",
          form: "access-form",
          service_id: 4
        },
        {
          title: "Locaciones",
          component: "location-icon",
          form:"location-form",
          service_id: 5
        },
        // {
        //   title: "Personal trainer",
        //   component: "personal-trainer-icon",
        //   form:"personal-trainer-form",
        //   service_id: 6
        // },
        {
          title: "Mensajería",
          component: "message-icon",
          form:"messages-form",
          service_id: 6
        },
        {
          title: "Noticias",
          component: "news-icon",
          form:"news-form",
          service_id: 7
        },
        // {
        //   title: "Eventos",
        //   component: "events-icon",
        //   form:"events-form",
        //   service_id: 9
        // },
        // {
        //   title: "Conexión de clientes internos",
        //   component: "client-connections-icon",
        //   form:"company-connections-form",
        //   service_id: 10
        // },
        {
          title: "Información del cliente",
          component: "information-icon",
          form:"client-information-form",
          service_id: 8
        },
        {
          title: "Personalización",
          component: "personalize-icon",
          form: "personalize-form",
          service_id: 9
        },
        // {
        //   title: "Trámites internos",
        //   component: "inside-request-icon",
        //   form:"inside-request-form",
        //   service_id: 13
        // },
        {
          title: "Conexión API",
          component: "api-connection-icon",
          form: "api-connections-form",
          service_id: 10
        }
      ]
    }
  },
  mounted(){
    this.updateShowDrawer(false)
    this.filter_sections.push(this.find_module(0))
    this.filter_sections.push(this.find_module(1))
    this.filter_sections.push(this.find_module(2))
    this.charged = true
    console.log(this.$refs);
    console.log(this.company);
    this.find_client()
    if(this.$route.params.id){
      this.prevent_edit = true
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  methods:{
    find_client(){
      try {
        this.$http.get('companies/' + this.$route.params.id ,{
          headers:
          {"X-Device-ID" : this.getDeviceId(),
          //  "Authorization" : 'Bearer ' + this.getUserToken()
          },
        })
        .then(function(response){
          console.log("DONE");
          console.log(response.body.data);
          this.company.description = response.body.data.attributes.description
          this.company.company_name = response.body.data.attributes.name
          this.company.address = response.body.data.attributes.address
          this.company.identify_number = response.body.data.attributes.identify_number
          this.company.contact_email = response.body.data.attributes.contact_email
          if(response.body.meta != undefined && response.body.meta != null){
            if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
              this.checkToken(response.body.meta.authentication_token)
            }
            if(response.body.meta.admin != undefined && response.body.meta.admin != null){
              this.company.email = response.body.meta.admin.email
              this.company.document_number = response.body.meta.admin.rut
            }
          }
          this.matchCompanyInfo(response.body.data.attributes)
          console.log("--------------------------Company");
          console.log(this.getCompany());
        }, function(response){
          console.log("FAIL");
          if(response.body.meta != undefined && response.body.meta != null){
            if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
              this.checkToken(response.body.meta.authentication_token)
            }
          }
          console.log(response);
        })
      } catch (e) {
        alert(e.message)
      }
    },
    edit_client(){
      this.updateCompanyUserName(this.company.user_name)
      this.updateCompanyDescription(this.company.description)
      this.updateCompanyDocumentNumber(this.company.document_number)
      this.updateCompanyEmail(this.company.email)
      this.updateCompanyCompanyName(this.company.company_name)
      this.updateCompanyAddress(this.company.address)
      this.updateCompanyIdentifyNumber(this.company.identify_number)
      this.updateCompanyContactEmail(this.company.contact_email)
      let personalize = this.getCompanyPersonalize()
      personalize.app_logo = this.app_logo
      console.log("<-----------Personalize");
      console.log(personalize);
      this.updateCompanyPersonalize(personalize)
      console.log("<-----------Company");
      console.log(this.getCompany());
      try{
        this.$http.put("companies/" + this.$route.params.id, {
          data: this.encrypt({attributes:this.getCompany()}).toString()
        }, {
          headers: {
            "X-Device-ID" : this.buildDeviceId(),
            "Authorization": "Bearer " + this.getUserToken(),
          }
        }).then(function(response){
          console.log(response);
          this.clearData()
          this.$router.push({name: 'root'})
        }, function(response){
          console.log(response);
        });
      }catch(e){
        alert(e.message);
      }

    },
    find_module(number){
      let filter_services = []
      let flag = number
      console.log(this.services.length);
      for (var i = 0; i <= this.services.length; i++) {
        if(i >= flag && flag < this.services.length){
          console.log(flag);
          filter_services.push(this.services[flag])
          flag += 3
        }
      }
      console.log(filter_services);
      return filter_services
    },
    select_service(index){
      if (index == this.service_selected){
        this.service_selected = null
      }else{
        this.service_selected = index
      }
    },
    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      var name_field = ""+e.target.name
      this.url = URL.createObjectURL(files[0]);
      this.createImage(files[0], name_field)
    },
    createImage (file) {
      var reader = new FileReader()
      var vm = this
      reader.readAsDataURL(file);
      reader.onload = function () {
        vm.app_logo = reader.result
        console.log(vm.app_logo);
      }
    },
  }
}
</script>

<style lang="css">
</style>

<template>
  <article class="home">
    <section class="home__title">
      <p>Clientes</p>
      <div
        class="home__button"
        @click="$router.push({name: 'new_client'})">
        Crear cliente
      </div>
    </section>
    <section class="home__list">
      <v-list
        subheader
        two-line>
        <v-list-item
          v-for="company in companies"
          :key="company.id"
          class="home__list_item">
          <div
            class="home__list--image"
            :style="{backgroundImage: 'url('+company.attributes.logo.thumb.url+')'}">
          </div>
          <v-list-item-content>
            <v-list-item-title v-text="company.attributes.name"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({name:'client_instance', params:{id: company.id}})">
              <v-icon color="grey lighten-1">more_vert</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </section>
  </article>
</template>

<script>
  export default {
    data(){
      return{
        companies: []
      }
    },
    mounted(){
      this.updateShowDrawer(true)
      this.findCompanies()
    },
    methods:{
      findCompanies(){
        try {
          this.$http.get('companies',{
            headers:
            {"X-Device-ID" : this.getDeviceId(),
            //  "Authorization" : 'Bearer ' + this.getUserToken()
            },
          })
          .then(function(response){
            this.companies = response.body.data
            console.log(response.body.data);
            // this.pages = response.body.meta.pages
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
          }, function(response){
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
            console.log(response);
          })
        } catch (e) {
          alert(e.message)
        }
      }
    }
  }
</script>

<style scoped>
</style>

import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

Vue.mixin({
  data(){
    return {
      company:{
        user_name: '',
        description:"",
        document_number: "",
        email: "",
        company_name: "",
        address: "",
        identify_number: "",
        dashboard:{
          users: false,
          offers: false,
          rewards: false,
          access: false
        },
        offers:{
          redemption_form:""
        },
        rewards:{
          user_characteristics: false,
          segments: false,
          rewards: false
        },
        access:{
          general_access:'',
          without_internet_access: false,
          keyboard_access: false,
          dynamic_invitations: false,
          room_bookings: '',
          courtesies: false,
          covenants: false,
          reports: false,
          alerts: '',
          counter: false,
          user_access: false,
          embed_qr: false
        },
        locations:{
          offers_and_rewards: false,
          sublocations: {
            time_out: false,
            bookings: false
          },
          holidays: false
        },
        personal_trainer:{
          personal_trainer: false
        },
        messages:{
          notifications:false,
          segment_mails: false
        },
        news:{
          general_news: false,
          video_news: false,
          twits_news:false
        },
        events:{
          tickets: false
        },
        company_connections:{
          company_connections: false
        },
        client_information: {
          us: false,
          contact_us: false
        },
        personalize:{
          web_page: false,
          mobile_app: false,
          colors: {
            principal:'',
            secundary:''
          },
          app_logo: '',
          principal_sections: [],
          secondary_sections: [],
          personalize_mails: false
        },
        inside_request:{
          payment_certificate: false,
          work_certificate: false,
          contribution_certificate: false
        },
        api_connections:{
          crm: false,
          end_points: false
        }
      }
    }
  },
  methods:{
    checkToken(token){
      if (this.getUserToken() != token) {
        this.updateUserToken(token);
      }
    },
    encrypt(data) {
      var CryptoJS = require("crypto-js");
      var payload = "G7qeaR2Yb4DAgk92ZQHdjQ==|gfYvWJ0vVdX142/xpsGPW8Fsgc/dGeQPFtaazcUMjQo=|VF/bl6Ae5CVTtQTPCrmAgQ==".split("|");
      var iv = payload[0], hmac = payload[1], cipherText = payload[2];
      var passphraseDgst = CryptoJS.SHA256("ImAwesomeAndHappy").toString();
      var key = passphraseDgst;
      console.log("<---------CryptoJS");
      console.log(iv, hmac, cipherText, key);
      var encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CryptoJS.enc.Hex.parse(passphraseDgst),
        {iv:CryptoJS.enc.Base64.parse(iv)}
      );
      return encrypted
    },
    buildDeviceId(){
      var uuid
      if(localStorage.device_id != undefined &&
         localStorage.device_id != null &&
         localStorage.device_id != ''){
        uuid = localStorage.device_id
      } else {
        uuid = this.$uuid.v1()
        localStorage.device_id = uuid
      }
      this.updateDeviceId(uuid);
      return uuid
    },
    clearData(){
      var company = {
        user_name: '',
        description:"",
        document_number: "",
        email: "",
        company_name: "",
        address: "",
        identify_number: "",
        dashboard:{
          users: false,
          offers: false,
          rewards: false,
          access: false
        },
        offers:{
          redemption_form:"",
          favorites: false
        },
        rewards:{
          user_characteristics: false,
          segments: false,
          rewards: false
        },
        access:{
          general_access:'',
          without_internet_access: false,
          keyboard_access: false,
          dynamic_invitations: false,
          room_bookings: '',
          courtesies: false,
          covenants: false,
          reports: false,
          alerts: false,
          counter: false,
          user_access: false,
          embed_qr: false
        },
        locations:{
          offers_and_rewards: false,
          sublocations: {
            time_out: false,
            bookings: false
          },
          holidays: false
        },
        personal_trainer:{
          personal_trainer: false
        },
        messages:{
          notifications:false,
          segment_mails: false
        },
        news:{
          general_news: false,
          video_news: false,
          twits_news:false
        },
        events:{
          tickets: false
        },
        company_connections:{
          company_connections: false
        },
        client_information: {
          us: false,
          contact_us: false,
          work_with_us: false,
          directory: false
        },
        personalize:{
          web_page: false,
          mobile_app: false,
          colors: {
            principal: '',
            secundary:''
          },
          app_logo: '',
          principal_sections: [],
          secondary_sections: [],
          personalize_mails: false
        },
        inside_request:{
          payment_certificate: false,
          work_certificate: false,
          contribution_certificate: false,
          payroll_credit: false
        },
        api_connections:{
          crm: false,
          end_points: false
        }
      }
      this.updateCompany(company)
      this.updateValidationOffers(false)
      this.updateValidationRewards(false)
      this.updateValidationFavorites(false)
      this.updateValidationInvitations(false)
      this.updateValidationRoomBookings(false)
      this.updateValidationLocations(true)
      this.updateValidationPersonalTrainer(false)
      this.updateValidationNews(false)
      this.updateValidationEvents(false)
      this.updateValidationCompanyConnections(false)
      this.updateValidationUs(true)
      this.updateValidationContactUs(true)
      this.updateValidationInsideRequest(false)
      this.updateValidationWorkWithUs(false)
      this.updateValidationDirectory(false)
    },
    ...mapActions([
      'updateUserToken',
      'updateUserId',
      'updateNameUser',
      'updateEmailUser',
      'updateUserType',
      'updateDeviceId',
      'updateShowDrawer',
      'updateShowModal',
      'updateCompany',
      'updateCompanyUserName',
      'updateCompanyDescription',
      'updateCompanyDocumentNumber',
      'updateCompanyEmail',
      'updateCompanyCompanyName',
      'updateCompanyAddress',
      'updateCompanyIdentifyNumber',
      'updateCompanyContactEmail',
      'updateCompanyDashboard',
      'updateCompanyOffers',
      'updateCompanyRewards',
      'updateCompanyAccess',
      'updateCompanyLocations',
      'updateCompanyPersonalTrainer',
      'updateCompanyMessages',
      'updateCompanyNews',
      'updateCompanyEvents',
      'updateCompanyCompanyConnections',
      'updateCompanyClientInformation',
      'updateCompanyPersonalize',
      'updateCompanyInsideRequest',
      'updateCompanyApiConnections',
      'updateValidationOffers',
      'updateValidationRewards',
      'updateValidationFavorites',
      'updateValidationInvitations',
      'updateValidationRoomBookings',
      'updateValidationLocations',
      'updateValidationPersonalTrainer',
      'updateValidationNews',
      'updateValidationEvents',
      'updateValidationCompanyConnections',
      'updateValidationUs',
      'updateValidationContactUs',
      'updateValidationInsideRequest',
      'updateValidationWorkWithUs',
      'updateValidationDirectory',
      'updateIsLogin',
      'updateUserIdEncrypt'
    ]),
    ...mapGetters([
      'getUserToken',
      'getUserId',
      'getNameUser',
      'getEmailUser',
      'getUserType',
      'getDeviceId',
      'getShowDrawer',
      'getShowModal',
      'getCompany',
      'getCompanyUserName',
      'getCompanyDescription',
      'getCompanyDocumentNumber',
      'getCompanyEmail',
      'getCompanyCompanyName',
      'getCompanyAddress',
      'getCompanyIdentifyNumber',
      'getCompanyContactEmail',
      'getCompanyDashboard',
      'getCompanyOffers',
      'getCompanyRewards',
      'getCompanyAccess',
      'getCompanyLocations',
      'getCompanyPersonalTrainer',
      'getCompanyMessages',
      'getCompanyNews',
      'getCompanyEvents',
      'getCompanyCompanyConnections',
      'getCompanyClientInformation',
      'getCompanyPersonalize',
      'getCompanyInsideRequest',
      'getCompanyApiConnections',
      'getValidationOffers',
      'getValidationRewards',
      'getValidationFavorites',
      'getValidationInvitations',
      'getValidationRoomBookings',
      'getValidationLocations',
      'getValidationPersonalTrainer',
      'getValidationNews',
      'getValidationEvents',
      'getValidationCompanyConnections',
      'getValidationUs',
      'getValidationContactUs',
      'getValidationInsideRequest',
      'getValidationWorkWithUs',
      'getValidationDirectory',
      'getIsLogin',
      'getUserIdEncrypt'
    ]),
    matchCompanyInfo(obj){
      let dashboard = {
                        users: obj.dashboard_users,
                        offers: obj.dashboard_offers,
                        rewards: obj.dashboard_rewards,
                        access: obj.dashboard_access
                      }
      let offers = {
                      favorites: obj.offers_favorites,
                      redemption_form: obj.offers_redemption_form
                    }
      let rewards = {
                      user_characteristics: obj.rewards_user_characteristics,
                      segments: obj.rewards_segments,
                      rewards: obj.rewards_rewards
                    }
      let access = {
                      general_access: obj.access_general_access,
                      without_internet_access: obj.access_without_internet_access,
                      keyboard_access: obj.access_keyboard_access,
                      dynamic_invitations: obj.access_dynamic_invitations,
                      room_bookings: obj.access_room_bookings,
                      courtesies: obj.access_courtesies,
                      covenants: obj.access_covenants,
                      reports: obj.access_reports,
                      alerts: obj.access_alerts,
                      counter: obj.access_counter,
                      user_access: obj.access_user_access,
                      embed_qr: obj.access_embed_qr
                    }
      let messages = {
                        notifications: obj.messages_notifications,
                        segment_mails: obj.messages_segment_mails,
                        whatsapp: obj.messages_whatsapp
                      }
      let news = {
                    general_news: obj.news_general_news,
                    video_news: obj.news_video_news,
                    twits_news: obj.news_twits_news
                  }
      let client_information = {
                                us: obj.client_information_us,
                                contact_us: obj.client_information_contact_us,
                                work_with_us: obj.client_information_work_with_us,
                                directory: obj.client_information_directory
                              }
      let personalize = {
                          web_page: obj.personalize_web_page ,
                          mobile_app: obj.personalize_mobile_app ,
                          colors: obj.personalize_colors ,
                          app_logo: obj.logo.url ,
                          principal_sections: obj.personalize_principal_sections ,
                          personalize_mails: obj.personalize_personalize_mails
                        }
      let api_connections = {
                              crm: obj.api_connections_crm,
                              end_points: obj.api_connections_end_points
                            }
      let locations = {
                        offers_and_rewards: obj.locations_offers_and_rewards,
                        sublocations: obj.locations_sublocations,
                        holidays: obj.locations_holidays
                      }
      this.updateCompanyDashboard(dashboard)
      this.updateCompanyOffers(offers)
      this.updateCompanyRewards(rewards)
      this.updateCompanyAccess(access)
      this.updateCompanyLocations(locations)
      this.updateCompanyMessages(messages)
      this.updateCompanyNews(news)
      this.updateCompanyClientInformation(client_information)
      this.updateCompanyPersonalize(personalize)
      this.updateCompanyApiConnections(api_connections)

      this.updateValidationOffers(offers.favorites)
      this.updateValidationRewards(rewards.rewards)
      this.updateValidationFavorites(offers.favorites)
      this.updateValidationInvitations(access.dynamic_invitations)
      this.updateValidationRoomBookings(access.general_access != '' ? true : false)
      this.updateValidationLocations(access.general_access != '' ? true : false)
      this.updateValidationNews(news.general_news)
      this.updateValidationCompanyConnections(true)
      this.updateValidationUs(client_information.us)
      this.updateValidationContactUs(client_information.contact_us)
      this.updateValidationWorkWithUs(client_information.work_with_us)
      this.updateValidationDirectory(client_information.directory)
    }
  }
})

<template>
  <article class="generic_form">
    <div class="generic_form__section">
      <v-checkbox
      v-model="news.general_news"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">General</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="news.video_news"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Video</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="news.twits_news"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Tuit</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  data(){
    return{
      news:{
        general_news: false,
        video_news: false,
        twits_news: false
      }
    }
  },
  mounted(){
    this.news = this.getCompanyNews()
  },
  beforeDestroy(){
    this.updateCompanyNews(this.news)
    if(this.news.general_news ||
       this.news.video_news ||
       this.news.twits_news){
      this.updateValidationNews(true)   
    }
    console.log(this.getCompanyNews());
  }
}
</script>

<style lang="css">
</style>

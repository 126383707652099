<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.829"
    height="10.414"
    viewBox="0 0 18.829 10.414">
    <path 
      fill="none"
      stroke="#abb0c4"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2px"
      d="M1934.657,764.7l8,8,8-8"
      transform="translate(1952.072 773.699) rotate(180)"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

/* eslint-disable no-new */
import Vue from 'vue'
import App from './App.vue'
import i18n from './vue/i18n/index'
import router from './vue/router/index'
import store from './vue/store/index'
import './vue/resource/index'
import './vue/components'
import './vue/filters'
import './vue/mixins'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import Verte from 'verte';
import 'verte/dist/verte.css';
import UUID from "vue-uuid";
import { ValidationProvider } from 'vee-validate';

Vue.component('verte', Verte);
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(UUID);
  // register component globally
new Vue({
  el: '#app',
  router: router,
  render: h => h(App),
  i18n: i18n,
  store: store,
  vuetify
})

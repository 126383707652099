<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <path
      fill="#23c5f5"
      d="M22.989,11.342A14.907,14.907,0,0,0,11.341,22.915C9.18,33.922,19.083,42.081,26.479,40.924a3.7,3.7,0,0,0,2.5-5.37,3.958,3.958,0,0,1,3.559-5.787h4.622a3.828,3.828,0,0,0,3.883-3.8,15.029,15.029,0,0,0-18.05-14.629ZM16.656,29.79a1.862,1.862,0,1,1,1.327-.546A1.873,1.873,0,0,1,16.656,29.79Zm1.872-7.5a1.862,1.862,0,1,1,1.327-.546A1.873,1.873,0,0,1,18.528,22.29Zm7.488-3.75a1.862,1.862,0,1,1,1.327-.546,1.873,1.873,0,0,1-1.327.546ZM33.5,22.29a1.862,1.862,0,1,1,1.327-.546A1.873,1.873,0,0,1,33.5,22.29Z"
      transform="translate(-11.039 -11.033)"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

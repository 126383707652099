<template>
  <article style="width: 100%">
    <section class="generic_form">
      <div class="generic_form__section">
        <v-checkbox
        v-model="activation_access"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Activar</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="access.without_internet_access"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Sin internet</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="access.keyboard_access"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Teclado (PIN de acceso)</p>
        </div>
      </div>
    </section>
    <section class="generic_form">
      <div class="generic_form__section">
        <v-checkbox
        v-model="access.dynamic_invitations"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Invitaciones dinámicas</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="access.reports"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Reportes</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="access.alerts"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Alertas</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="access.counter"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">
            Counter (Aplicativo de escritorio)
          </p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="access.user_access"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Usuarios</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="access.embed_qr"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">QR de invitación embebido</p>
        </div>
      </div>
    </section>
    <section class="generic_form">
      <div class="generic_form__section">
        <v-text-field
          class="generic_form__section generic_form__section--text_section"
          label="No."
          v-model="access.active_booking_size"
        ></v-text-field>
        <div class="generic_form__section">
          <p class="generic_form__section--title">Cantidad de reservas activas</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-text-field
          class="generic_form__section generic_form__section--text_section"
          label="No."
          v-model="access.bookin_limit_per_day"
        ></v-text-field>
        <div class="generic_form__section">
          <p class="generic_form__section--title">Cantidad de reservas al dia</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="access.single_access_per_booking"
        ></v-checkbox>
        <div class="generic_form__section">
          <p class="generic_form__section--title">Unico acceso por dia</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="access.virtual_guard"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Vigilante virtual</p>
        </div>
      </div>
    </section>

    <app-modal>
      <div class="" v-if="modal_type == 'general_access'">
        <div class="modal__title_container">
          <p class="modal__title">Acceso general</p>
          <span class="modal__subtitle">Selecciona una opción</span>
        </div>
        <section>
          <v-radio-group v-model="temp_value">
            <div class="modal__subcontainer modal__subcontainer--align_center">
              <v-radio
                :value="'permanent_key'"
              ></v-radio>
              <div class="modal__radio_text">
                <p>Llave permanente</p>
              </div>
            </div>
            <div class="modal__subcontainer modal__subcontainer--align_center">
              <v-radio
                :value="'booking'"
              ></v-radio>
              <div class="modal__radio_text">
                <p>Agendamiento</p>
              </div>
            </div>
          </v-radio-group>

        </section>
        <section class="modal__buttons">
          <div
            class="modal__buttons--cancel modal__buttons--button"
            @click="updateShowModal(false)">
            Cancelar
          </div>
          <div
            class="modal__buttons--active modal__buttons--button"
            @click="save_value()">
            Guardar
          </div>
        </section>
      </div>
      <div class="" v-else-if="modal_type == 'booking_rooms'">
        <div class="modal__title_container">
          <p class="modal__title">Reservas de salas</p>
          <span class="modal__subtitle">Selecciona una opción</span>
        </div>
        <section>
          <v-radio-group v-model="temp_booking_value">
            <div class="modal__subcontainer modal__subcontainer--align_center">
              <v-radio
                :value="'with_slave'"
              ></v-radio>
              <div class="modal__radio_text">
                <p>Con dispositivo</p>
              </div>
            </div>
            <div class="modal__subcontainer modal__subcontainer--align_center">
              <v-radio
                :value="'with_qr'"
              ></v-radio>
              <div class="modal__radio_text">
                <p>Con QR impreso</p>
              </div>
            </div>
          </v-radio-group>

        </section>
        <section class="modal__buttons">
          <div
            class="modal__buttons--cancel modal__buttons--button"
            @click="updateShowModal(false)">
            Cancelar
          </div>
          <div
            class="modal__buttons--active modal__buttons--button"
            @click="save_booking_value()">
            Guardar
          </div>
        </section>
      </div>

    </app-modal>


  </article>
</template>

<script>
export default {
  data(){
    return{
      activation_access: false,
      modal_type:"",
      check_general_access: false,
      check_booking_rooms: false,
      check_alerts: false,
      temp_value:'',
      temp_booking_value: '',
      access:{
        general_access: '',
        without_internet_access: false,
        keyboard_access: false,
        dynamic_invitations: false,
        room_bookings: '',
        courtesies: false,
        covenants: false,
        reports: false,
        alerts: false,
        counter: false,
        user_access:false,
        embed_qr: false,
        active_booking_size: null,
        bookin_limit_per_day: null,
        single_access_per_booking: false,
        virtual_guard: false
      }
    }
  },
  watch:{
    temp_value(){
      this.check_general_access = true
    },
    temp_booking_value(){
      this.check_booking_rooms = true
      this.updateValidationRoomBookings(true)
    },
    activation_access(){
      if(this.activation_access){
        this.access.general_access = "all"
        this.access.without_internet_access = true
        this.access.keyboard_access = true
        this.access.dynamic_invitations = true
        this.access.room_bookings = 'with_qr'
        this.access.reports = true
        this.access.alerts = true
        this.access.counter = true
        this.access.user_access = true
      }else{
        this.access.general_access = ""
        this.access.without_internet_access = false
        this.access.keyboard_access = false
        this.access.dynamic_invitations = false
        this.access.room_bookings = ''
        this.access.reports = false
        this.access.alerts = false
        this.access.counter = false
        this.access.user_access = false
      }
    }
  },
  mounted(){
    this.updateShowModal(false)
    if( this.getCompanyAccess().general_access != '' ){
      this.check_general_access = true
    }else{
      this.check_general_access = false
    }
    if( this.getCompanyAccess().room_bookings != '' ){
      this.check_room_booking = true
    }else{
      this.check_room_booking = false
    }
    if( this.getCompanyAccess().alerts != '' ){
      this.check_alerts = true
    }else{
      this.check_alerts = false
    }
    this.access = this.getCompanyAccess()
    console.log(this.access);
    if(this.access.general_access == "all" ||
      this.access.without_internet_access == true ||
      this.access.keyboard_access == true ||
      this.access.dynamic_invitations == true ||
      this.access.room_bookings == 'with_qr' ||
      this.access.reports == true ||
      this.access.alerts == true ||
      this.access.counter == true ||
      this.access.user_access == true){
      this.activation_access = true
    }else{
      this.activation_access = false
    }
  },
  methods:{
    save_value(){
      this.access.general_access = this.temp_value;
      this.updateShowModal(false);
    },
    save_booking_value(){
      this.access.room_bookings = this.temp_bookin_value;
      this.updateShowModal(false);
    }
  },
  beforeDestroy(){
    this.updateCompanyAccess(this.access)
    this.updateValidationInvitations(this.access.dynamic_invitations)
    this.updateValidationRoomBookings(this.check_room_booking)
    console.log(this.getCompanyAccess());
  }
}
</script>

<style lang="css">
</style>

<template>
  <div class="navigation">
  <v-navigation-drawer
    v-model="drawer"
    fixed
    clipped
    class="white drawer"
    app>
    <v-list
      dense
      class="white navigation__container--drawer">
      <article
        v-for="(item, i) in items"
        v-bind:key="i">
        <v-layout
          v-if="item.title"
          :key="i"
          row>
          <v-flex
            xs6
            @click="$router.push({name: item.principal_route })">
            <v-subheader
              v-if="item.title"
              class="navigation__text--drawer_subtitle">
              {{ item.title }}
            </v-subheader>
          </v-flex>
        </v-layout>
        <v-list-item
          @click="changeRoute(item, link)"
          v-for="link in item.links"
          v-bind:key="link.text">
          <v-icon >{{ link.icon }}</v-icon>
          <v-list-item-content>
            <v-list-item-title class="navigation__container--item_text_container" >
              {{ link.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </article>
    </v-list>
    <div class="drawer__close_section" @click="closeSession()">
      Cerrar Sesión
    </div>
  </v-navigation-drawer>
</div>
</template>

<script>
  export default {
    data: () => ({
      drawer: null,
      selected_item:'',
      selected_subitem:'',
      current_name:'Dashboard',
      items: [
        {
          title: '',
          divider: true,
          principal_route: '',
          section: 'offer',
          id:0,
          links: [
            { icon: 'account_box', text: 'Clientes', route: 'root' }
          ],
        },
      ]
    }),
    watch: {
      selected_item: function() {
        if(this.selected_item==='classnav'){
          this.current_name='Clases';
        }
        else if(this.selected_item==='offer'){
          this.current_name='Ofertas';
        }
        else if(this.selected_item==='access'){
          this.current_name='Acceso';
        }
        else if(this.selected_item==='location'){
          this.current_name='Locaciones';
        }
        else if(this.selected_item==='mensajerias'){
          this.current_name='Mensajería';
        }
        else if(this.selected_item==='notificaciones'){
          this.current_name='Notificaciones';
        }
        else if(this.selected_item==='caracteristicas'){
          this.current_name='Características';
        }
        else if(this.selected_item==='offline_counter_section'){
          this.current_name='Offline Counter Menú';
        }else if(this.selected_item==='access_counter'){
          this.current_name='Counter'
        }
        else{
          this.current_name='Dashboard';
        }
      }
    },
    methods:{
      closeSession(){
        this.updateUserToken(null)
        this.updateIsLogin(false)
        this.$router.push({name:"login"})
      },
      changeRoute(item, link){
        this.selected_subitem = item.id+link.text;
        if(link.current_section != null &&
           link.current_section != undefined &&
           link.current_section != ''){
           this.updateDashboardActive(link.current_section)
        }
        if(this.selected_subitem=='14Inicio'){
          this.$router.push({name: 'location_access', params: {id: localStorage.getItem('location_id'), filter:false, date: new Date().toISOString().substr(0, 10), date2: new Date().toISOString().substr(0, 10), namex: localStorage.getItem('location_name')}})
        }else if(this.selected_subitem=="14Resumen locación"){
          this.$router.push({name: 'access_counter', params: {id: localStorage.getItem('location_id'), filter:false,date: new Date().toISOString().substr(0, 10),date2:new Date().toISOString().substr(0, 10),namex:localStorage.getItem('location_name')}})
        }else if(this.selected_subitem=="15Generar Reserva"){
          this.$router.push({name: 'booking_index', params: {id: localStorage.getItem('location_id'), location_name: localStorage.getItem('location_name')}})
        }else{
          this.$router.push({name: item.principal_route!='' ? item.principal_route +'_'+link.route : link.route})
        }
      },
      handleConnectivityChange(status) {
        this.updateIsLogin(false);
        this.$router.push({path:'/'})
        let  val="Se ha desconectado de internet, por favor vuelva a ingresar para entrar al modo Offline"
        if(status){
          val="Se ha conectado de nuevo a internet, por favor vuelva a ingresar"
        }
        if(navigator.userAgent=="Sportlife-Counter-Desktop-App"){
          this.updateAlertMessage(val)
          this.updateIsInAlert(true)
        }else{
          alert(val)
        }
      }
    }
  }
</script>

<template>
  <article style="width: 100%">
    <section class="generic_form">
      <div class="generic_form__section">
        <v-checkbox
        v-model="locations.offers_and_rewards"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Ofertas y Rewards</p>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  data(){
    return{
      temp_time_out: false,
      temp_bookings: false,
      sublocations: false,
      locations:{
        offers_and_rewards: false,
        sublocations: {
          time_out: true,
          bookings: true
        },
        holidays: true,
      }
    }
  },
  methods:{
    save_value(){
      this.locations.sublocations.time_out = this.temp_time_out
      this.locations.sublocations.bookings = this.temp_bookings
      if(this.temp_bookings || this.temp_time_out ){
        this.sublocations = true
      } else {
        this.sublocations = false
      }
      this.updateShowModal(false)
    },
    cancel_value(){
      this.temp_time_out = this.getCompanyLocations().sublocations.time_out
      this.temp_bookings = this.getCompanyLocations().sublocations.bookings
      this.updateShowModal(false)
    }
  },
  mounted(){
    this.locations = this.getCompanyLocations()
    console.log(this.locations);
    if(this.locations.sublocations.bookings == true || this.locations.sublocations.time_out == true ){
      this.sublocations = true
    } else {
      this.sublocations = false
    }
    this.temp_time_out = this.locations.sublocations.time_out
    this.temp_bookings = this.locations.sublocations.bookings
  },
  beforeDestroy(){
    this.updateCompanyLocations(this.locations)
    this.updateValidationLocations(true)
    console.log(this.getCompanyLocations());
  }
}
</script>

<style lang="css">
</style>

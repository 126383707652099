import { render, staticRenderFns } from "./Hello.vue?vue&type=template&id=2577e654&scoped=true&"
import script from "./Hello.vue?vue&type=script&lang=js&"
export * from "./Hello.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2577e654",
  null
  
)

export default component.exports
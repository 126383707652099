<template>
  <article class="generic_form">
    <div class="generic_form__section">
      <v-checkbox
      v-model="inside_request.payment_certificate"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Certificado de nomina</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="inside_request.work_certificate"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Certificado laboral</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="inside_request.contribution_certificate"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Certificado de aportes</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="inside_request.payroll_credit"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Crédito de libranza</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  data(){
    return{
      inside_request:{
        payment_certificate: false,
        work_certificate: false,
        contribution_certificate: false,
        payroll_credit: false
      }
    }
  },
  mounted(){
    this.inside_request = this.getCompanyInsideRequest()
  },
  beforeDestroy(){
    this.updateCompanyInsideRequest(this.inside_request)
    if(this.inside_request.payment_certificate ||
       this.inside_request.work_certificate ||
       this.inside_request.contribution_certificate){
      this.updateValidationInsideRequest(true)
    }
    console.log(this.getCompanyInsideRequest());
  }
}
</script>

<style lang="css">
</style>

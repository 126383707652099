<template>
  <article class="generic_form">
    <div class="generic_form__section">
      <v-checkbox
      v-model="client_information.us"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Nosotros</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="client_information.contact_us"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Contáctenos</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="client_information.work_with_us"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Trabaja con nosotros</p>
      </div>
    </div>
    <div class="generic_form__section">
      <v-checkbox
      v-model="client_information.directory"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Directorio interno</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  data(){
    return{
      client_information:{
        us: false,
        contact_us: false,
        work_with_us: false,
        directory: false
      }
    }
  },
  mounted(){
    this.client_information = this.getCompanyClientInformation()
  },
  beforeDestroy(){
    this.updateCompanyClientInformation(this.client_information)
    this.updateValidationUs(this.client_information.us)
    this.updateValidationContactUs(this.client_information.contact_us)
    this.updateValidationWorkWithUs(this.client_information.work_with_us)
    this.updateValidationDirectory(this.client_information.directory)
    console.log(this.getCompanyClientInformation());
  }
}
</script>

<style lang="css">
</style>

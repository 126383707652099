import Hello from './../../components/Hello.vue'
import ClientForm from './../../components/views/ClientForm.vue'
import ClientInstance from './../../components/views/ClientInstance.vue'
import Login from './../../components/shared/Login.vue'

export default [
  { path: '/', component: Hello, name: 'root' },
  { path: '/login', component: Login, name: 'login' },
  { path: '/new_client', component: ClientForm, name: 'new_client' },
  { path: '/client/:id', component: ClientInstance, name: 'client_instance' }
]

<template>
  <login v-if="!getIsLogin()"></login>
  <v-app v-else id="inspire">
    <v-app-bar app>
      <v-app-bar-nav-icon @click="updateShowDrawer(!getShowDrawer())"></v-app-bar-nav-icon>
      <v-toolbar-title><app-logo class="app_logo"></app-logo></v-toolbar-title>
    </v-app-bar>
    <v-main>
      <div id="main-wrapper">
        <navigation v-if="getShowDrawer()"></navigation>
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({ drawer: true }),
    mounted(){
      this.buildDeviceId()
      if(this.getUserToken() === null ||
         this.getUserToken() === '' ||
         this.getUserToken() === undefined) {
        this.$router.push({name: 'login'})
      }
    }
  }
</script>

<style lang="scss">
  @import './assets/stylesheets/application.scss';
  header{
    left: 0 !important;
  }
</style>

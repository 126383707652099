<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="36"
    viewBox="0 0 27 36">
    <path
      fill="#51c97b"
      d="M16.5,0A13.564,13.564,0,0,0,3,13.6C3,24.252,15.231,35.253,15.752,35.715a1.128,1.128,0,0,0,1.5,0C17.769,35.253,30,24.252,30,13.6A13.564,13.564,0,0,0,16.5,0Zm0,21A7.5,7.5,0,1,1,24,13.5,7.509,7.509,0,0,1,16.5,21Z"
      transform="translate(-3)"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

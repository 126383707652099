<template>
  <v-app id="inspire">
    <v-content class="login">
      <v-layout row wrap fill-height>
        <v-flex
          xs12 lg6
          class="login_content"
        >
          <img src="@/assets/img/elipse.png" class="login_content__elipse" />
          <div class="login_content__ctelipse"></div>
          <v-layout class="login_content__logo">
            <img src="@/assets/img/logo.png" class="login_content__logo--logo" />
          </v-layout>
          <v-layout
            class="login_content__form"
            row wrap full-width
            :style="{'background-image': 'url(' + require('../../assets/img/f-marca-agua.png') + ')'}"
          >
            <p class="login_content__form--title">Iniciar sesión</p>

                <p
                  class="content_form__label"
                  :class="{'error_text': error_email !== '' && user.email === ''}"
                >
                  Correo electrónico
                </p>
                <input
                  type="text"
                  class="content_form__input"
                  placeholder="Escribe tu correo de acceso"
                  v-model="user.email"
                  :class="{'error_input': error_email !== '' && user.email === ''}"
                >

                <p
                  class="content_form__label"
                  :class="{'error_text': error_pass !== '' && user.password === ''}"
                >
                  Contraseña
                </p>
                <input
                  type="password"
                  class="content_form__input"
                  placeholder="Escribe tu contraseña"
                  v-model="user.password"
                  :class="{'error_input': error_pass !== '' && user.password === ''}"
                >

                <h5
                  v-if="loadingMessage.length>0"
                  style="margin-left:20px;color:#2196f3;font-size:16px;position:relative;"
                ><i>{{loadingMessage}}</i></h5>
                <v-spacer></v-spacer>

                <!--<v-layout>
                  <v-flex lg6>
                    <v-checkbox
                      v-model="allowSpaces"
                      label="Recordarme"
                      class="content_form__check"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex lg6 align-center d-flex>
                    <a href="#" class="content_form__link">Olvidé la contraseña</a>
                  </v-flex>
                </v-layout>-->

                <button
                  class="content_form__button"
                  :disabled="loadingMessage.length>0"
                  @click="signIn()"
                >
                  Ingresar
                </button>

                <a href="#" class="content_form__link no-underline">
                  2020 Fusepong. Todos los derechos reservados.
                </a>


            </v-layout>
          </v-flex>
        <v-flex xs12 lg6
          :style="{'background-image': 'url(' + require('../../assets/img/fondo-login.jpg') + ')', 'background-position': 'center center', 'background-size': 'cover'}"
        >
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      drawer: true,
      user:{
        email: '',
        password: '',
        rut:''
      },
      error_email: '',
      error_pass: '',
      sublocations:'',
      colors:'',
      users:{
        "1015417203":{name:"Wilson Montoya",category:"One", access_doors:[1,2,3]},
        "90020854269":{name:"Andres Fonseca",category:"Premium",access_doors:[5,6,7,8]}
      },
      loadingMessage:'',
      isOnLine:true
    }),
    props: {
      source: String
    },
    methods:{
      signIn(){
        var self = this;
        var payload = this.encrypt({attributes: this.user}).toString()
        try{
          if(this.user.email !== "" && this.user.password !== ""){
            console.log(payload);
            this.$http.post("users/sign_in", {
              data: payload
            },{
              headers:{
                "X-Device-ID" : this.getDeviceId(),
                "SADMIN" : "true"
              }
            }).then(function(response){
              if(response.body.meta.authentication_token != undefined &&
                response.body.meta.authentication_token != null &&
                response.body.meta.authentication_token != ''){
                if(response.body.meta.uuid != undefined &&
                  response.body.meta.uuid != null){
                  this.updateUserIdEncrypt(response.body.meta.uuid);
                }
                if(response.body.data.attributes.user_info_type=="super_admin"){
                  self.updateUserToken(response.body.meta.authentication_token)
                  self.updateUserId(response.body.data.id)
                  self.updateNameUser(response.body.data.attributes.email)
                  self.updateEmailUser(response.body.data.attributes.email)
                  self.updateUserType("SuperAdmin");
                  self.updateIsLogin(true);
                  // self.$router.push({name: 'summary'});
                  self.$router.push({name: 'root'});
                }
              }
            }, function(response){
              if(response.body.errors && typeof response.body.errors[0].details != "object"){
                if(self.isDesktopApp){
                  self.updateAlertMessage(response.body.errors[0].details)
                  self.updateIsInAlert(true)
                }else{
                  alert(response.body.errors[0].details)
                }
                self.user.password=''
              }else{
                if(response.status==0){
                  this.isOnLine = false;
                  this.offlineSignIn();
                }
                let message = []
                for(var key in response.body.errors[0].details){
                  message.push(response.body.errors[0].details[key])
                }
                if(self.isDesktopApp){
                  self.updateAlertMessage(message)
                  self.updateIsInAlert(true)
                }else{
                  alert(message)
                }
                self.user.password=''
              }
            });
          }
        }catch(e){
          console.log("---------------------ERROR!!!!!");
          console.log(e);
          alert(e.message);
        }
      }
    }
  }
</script>
<style scoped>

</style>

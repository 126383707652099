<template>
  <article class="generic_form">
    <div class="generic_form__section">
      <v-checkbox
      v-model="activation_rewards"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Activar</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  data(){
    return{
      activation_rewards: false,
      rewards:{
        user_characteristics: false,
        segments: false,
        rewards: false
      }
    }
  },
  watch:{
    activation_rewards(){
      if(this.activation_rewards){
        this.rewards.user_characteristics = true
        this.rewards.segments = true
        this.rewards.rewards = true
      }else{
        this.rewards.user_characteristics = false
        this.rewards.segments = false
        this.rewards.rewards = false
      }
    }
  },
  mounted(){
    this.rewards = this.getCompanyRewards()
    this.activation_rewards = this.getValidationRewards()
    if(this.rewards.user_characteristics ||
       this.rewards.segments ||
       this.rewards.rewards){
      this.activation_rewards = true
    }else{
      this.activation_rewards = false
    }
  },
  beforeDestroy(){
    this.updateCompanyRewards(this.rewards)
    this.updateValidationRewards(this.activation_rewards)
    console.log(this.getCompanyRewards());
  }
}
</script>

<style lang="css">
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="29"
    viewBox="0 0 30 29">
    <g transform="translate(-3.92 -4.8)">
      <path
        fill="#0dbf1b"
        d="M28.954,4.8H8.887A5.074,5.074,0,0,0,3.92,9.975V22.681a5.074,5.074,0,0,0,4.967,5.175H19.169l5.512,5.574a1.205,1.205,0,0,0,1.352.268,1.3,1.3,0,0,0,.759-1.2V27.856h2.161a5.074,5.074,0,0,0,4.967-5.175V9.975A5.075,5.075,0,0,0,28.954,4.8Zm2.484,17.881a2.537,2.537,0,0,1-2.484,2.588h-3.4a1.269,1.269,0,0,0-1.242,1.294v2.88l-3.768-3.812a1.216,1.216,0,0,0-.869-.365H8.887A2.537,2.537,0,0,1,6.4,22.678V9.975A2.537,2.537,0,0,1,8.887,7.388H28.954a2.537,2.537,0,0,1,2.484,2.588Zm-4.561-8.938a1.269,1.269,0,0,1-1.242,1.294H12.206a1.295,1.295,0,0,1,0-2.588H25.635a1.268,1.268,0,0,1,1.242,1.287Zm0,5.175a1.269,1.269,0,0,1-1.242,1.294H12.206a1.295,1.295,0,0,1,0-2.588H25.635a1.268,1.268,0,0,1,1.242,1.287Z"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

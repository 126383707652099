<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <g transform="translate(-10 -10)">
      <path
        fill="#2196f3"
        d="M10,10v8.75h8.75V10Zm6.25,6.25H12.5V12.5h3.75Z"/>
      <path
        fill="#2196f3"
        d="M10,66.666v8.75h8.75v-8.75Zm6.25,6.251H12.5v-3.75h3.75Z"
        transform="translate(0 -35.416)"/>
      <path
        fill="#2196f3"
        d="M66.667,10v8.75h8.75V10Zm6.25,6.25h-3.75V12.5h3.75Z"
        transform="translate(-35.417)"/>
      <path
        fill="#2196f3"
        d="M72.917,40v7.5h-6.25V50h8.75V40Z"
        transform="translate(-35.417 -18.75)"/>
      <path
        fill="#2196f3"
        d="M66.667,73.334v6.25h2.5v-3.75h3.75v3.75h2.5v-6.25Z"
        transform="translate(-35.417 -39.584)"/>
      <path
        fill="#2196f3"
        d="M40,10v2.5h5v6.25h2.5V10Z"
        transform="translate(-18.75)"/>
      <path
        fill="#2196f3"
        d="M45,40v5H40v7.5h5v6.25h2.5V50h-5V47.5h5v-5H50V45h2.5V40Z"
        transform="translate(-18.75 -18.75)"/>
      <rect
        fill="#2196f3"
        width="2.5"
        height="3.75"
        transform="translate(21.25 36.25)"/>
      <rect
        fill="#2196f3"
        width="3.75"
        height="2.5"
        transform="translate(15 26.25)"/>
      <path
        fill="#2196f3"
        d="M21.25,23.333v6.25H10v7.5h2.5v-5H23.75v-8.75Z"
        transform="translate(0 -8.333)"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

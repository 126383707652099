<template>
  <article class="generic_form">
    <div class="generic_form__section">
      <v-checkbox
      v-model="check_redemption_form"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Activar</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  data(){
    return{
      activation_offers: false,
      check_redemption_form: false,
      redemption_form: false,
      radioGroup: 1,
      temp_value: 'all',
      offers:{
        redemption_form: '',
        favorites: true
      }
    }
  },
  mounted(){
    this.offers = this.getCompanyOffers()
    if (this.offers.redemption_form == "scan_by_user"){
      this.temp_value = "scan_by_user"
    } else if(this.offers.redemption_form == "scan_by_agent") {
      this.temp_value = "scan_by_agent"
    } else{
      this.temp_value = ""
    }
    this.check_redemption_form = this.getValidationOffers()
  },
  watch:{
    temp_value(){
      this.check_redemption_form = true
      this.updateValidationOffers(true)
    },
  },
  methods:{
    save_value(){
      this.offers.redemption_form = this.temp_value;
      this.updateShowModal(false);
    }
  },
  beforeDestroy(){
    if(this.check_redemption_form){
      this.updateCompanyOffers(this.offers)
    }
    this.updateValidationOffers(this.check_redemption_form)
    this.updateValidationFavorites(this.check_redemption_form)
    console.log(this.getCompanyOffers());
  }
}
</script>

<style lang="css">
</style>

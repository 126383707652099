<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <g transform="translate(-276 -836)">
      <path
        fill="#f44a43"
        d="M22.862,25.713a8.616,8.616,0,0,1,1.125-4.237c-.375-.038-.75-.075-1.125-.075A12.862,12.862,0,1,0,35.725,34.263c0-.375-.037-.75-.075-1.125a8.549,8.549,0,0,1-12.788-7.425Z"
        transform="translate(266 818.875)"/>
      <path
        fill="#f4c443"
        d="M63.25,18.588A8.583,8.583,0,0,0,47.2,14.35,12.829,12.829,0,0,1,58.9,26.013,8.531,8.531,0,0,0,63.25,18.588Z"
        transform="translate(242.75 826)"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

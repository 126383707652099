<template>
  <article class="generic_form">
    <div class="generic_form__section">
      <v-checkbox
      v-model="personal_trainer.personal_trainer"
      ></v-checkbox>
      <div class="generic_form__section--text">
        <p class="generic_form__section--title">Clases</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  data(){
    return{
      personal_trainer:{
        personal_trainer: false
      }
    }
  },
  mounted(){
    this.personal_trainer = this.getCompanyPersonalTrainer()
  },
  beforeDestroy(){
    this.updateCompanyPersonalTrainer(this.personal_trainer)
    this.updateValidationPersonalTrainer(this.personal_trainer.personal_trainer)
    console.log(this.getCompanyPersonalTrainer());
  }
}
</script>

<style lang="css">
</style>

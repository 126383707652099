<template>
  <article style="width:100%">
    <section class="generic_form">
      <div class="generic_form__section">
        <v-checkbox
        v-model="personalize.web_page"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Página web</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="personalize.mobile_app"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Aplicativo móvil</p>
        </div>
      </div>
      <div class="generic_form__section">
        <v-checkbox
        v-model="check_colors"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Colores</p>
          <p
            class="generic_form__section--span"
            @click="select_colors = true; updateColors();updateShowModal(true)">Seleccionar colores</p>
        </div>
      </div>
    </section>
    <section class="generic_form">
      <p class="generic_form__text">Secciones</p>
      <div class="generic_form__section">
        <v-checkbox
        v-model="personalize.principal_sections"
        ></v-checkbox>
        <div class="generic_form__section--text">
          <p class="generic_form__section--title">Principales</p>
          <p
            class="generic_form__section--span"
            @click="select_sections = true; updateColors();updateShowModal(true)">Seleccionar secciones</p>
        </div>
      </div>
    </section>

    <app-modal v-if="select_colors">
      <div class="modal__title_container">
        <p class="modal__title">Colores personalizados</p>
        <span class="modal__subtitle">Llena los campos con los colores de la compañia</span>
      </div>
      <section>
        <div class="modal__radio_text" style="margin: 15px 0 10px">
          <p>Color principal*</p>
        </div>
        <div class="modal__subcontainer modal__subcontainer--fit_content">
          <div class="client__form--field" style="margin: 0">
            <input
              placeholder="Ej. #000"
              type="text"
              v-model="temp_principal" />
            <div
              class="client__form--color_field"
              v-bind:style = "{'backgroundColor': temp_principal, 'borderColor': temp_principal}">
            </div>
          </div>
        </div>
        <div class="modal__radio_text" style="margin: 15px 0 10px">
          <p>Color secundario*</p>
        </div>
        <div class="modal__subcontainer modal__subcontainer--fit_content">
          <div class="client__form--field" style="margin: 0">
            <input
              placeholder="Ej. #000"
              type="text"
              v-model="temp_secundary" />
            <div
              class="client__form--color_field"
              v-bind:style = "{'backgroundColor': temp_secundary, 'borderColor': temp_secundary}">
            </div>
          </div>
        </div>
      </section>
      <section class="modal__buttons">
        <div
          class="modal__buttons--cancel modal__buttons--button"
          @click="select_colors = false; clear_values()">
          Cancelar
        </div>
        <div
          class="modal__buttons--active modal__buttons--button"
          @click="select_colors = false; save_values()">
          Guardar
        </div>
      </section>
    </app-modal>

    <app-modal v-if="select_sections">

      <!-- <div class="modal__title_container">
        <p class="modal__title">Secciones principales</p>
        <span class="modal__subtitle">¿Cuantas secciones principales desea en su aplicativo?</span>
        <v-radio-group v-model="section_limit">
          <v-radio
            key="3"
            label="Hasta 3"
            value="3"
          ></v-radio>
          <v-radio
            key="5"
            label="Hasta 5"
            value="5"
          ></v-radio>
        </v-radio-group>
        <span v-if="charge_limit" class="modal__subtitle">Selecciona los servicios principales de tu compañia (Max {{section_limit.toString()}})</span>
      </div> -->

      <v-container fluid>
        <!-- <v-row align="center">
          <v-col
            class="d-flex"
            cols="12"
            sm="12">
            <v-text-field
              v-if="current_is_instance && ('title' in current_principal_section) > 0"
              label="Sección Principal"
              placeholder="Sección Principal"
              outlined
              :value="current_principal_section.title"
              append-icon="mdi-border-color"
              @click:append="editSections('principal')"
            ></v-text-field>
            <v-select
              v-else
              :items="section_options"
              label="Sección Principal"
              item-text="title"
              return-object
              dense
              v-model="principal_section"
              @change="change_principal_flag()"
            ></v-select>
          </v-col>
        </v-row> -->
        <v-row align="center" >
          <v-col
            class="d-flex"
            cols="12"
            :sm="section_limit > 3 ? '6' : '3'"
            v-for="(section, index) in section_limit"
            v-bind:key="index"
            >
            <v-text-field
              v-if="current_is_instance && current_selected_sections.length > 0 "
              :label="'Sección No.' + section"
              :placeholder="'Sección No.' + section"
              outlined
              :value="current_selected_sections[index].title ? current_selected_sections[index].title : 'Sin definir'"
              append-icon="mdi-border-color"
              @click:append="editSections('secundary')"
            ></v-text-field>
            <v-select
              v-else
              :items="getDifference(section_options, selected_sections)"
              filled
              :label="'Sección No.' + section"
              dense
              item-text="title"
              return-object
              v-model="other_sections[index]"
              ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <section class="modal__buttons">
        <div
          class="modal__buttons--cancel modal__buttons--button"
          @click="select_sections = false; clear_values()">
          Cancelar
        </div>
        <div
          class="modal__buttons--active modal__buttons--button"
          @click="select_sections = false; save_sections()">
          Guardar
        </div>
      </section>
    </app-modal>


  </article>
</template>

<script>
export default {
  data(){
    return{
      current_is_instance: false,
      current_principal_section: {},
      current_selected_sections: [],
      charge_limit: false,
      charge_principal: false,
      principal_section: {},
      other_sections:[{},{}],
      section_limit: 3,
      select_sections: false,
      select_colors: false,
      selected_sections:[],
      section_options: [],
      check_colors: false,
      temp_principal:'',
      temp_secundary:'',
      personalize:{
        web_page: false,
        mobile_app: false,
        app_logo: false,
        principal_sections: false,
        secondary_sections: false,
        personalize_mails: false,
        colors: {
          principal: '',
          secundary:''
        }
      }
    }
  },
  props:['is_instance'],
  computed:{

  },
  watch:{
    selected_sections(){
      if(!this.current_is_instance){
        console.log(this.selected_sections);
      }
    },
    section_limit(){
      if(!this.current_is_instance){
        if(this.section_limit == 3 ){
          // this.principal_section = {}
          this.other_sections = [{},{},{}]
          this.selected_sections = []
        }else{
          this.principal_section = {}
          this.other_sections = [{},{},{},{}]
          this.selected_sections = []
        }
        this.charge_limit = true
        this.charge_principal = false
      }
    },
    // principal_section(){
    //   if(!this.current_is_instance){
    //     this.principal_section.order = this.section_limit == 3 ? 1 : 2;
    //     this.principal_section.principal =  true
    //     this.selected_sections = [this.principal_section]
    //     console.log(this.selected_sections);
    //   }
    // }
  },
  methods:{
    editSections(section){
      if(section == "principal"){
        this.current_is_instance = false
        this.current_principal_section = {}
        this.current_selected_sections = []
        this.charge_principal = false
      }else if(section == "secundary"){
        this.current_selected_sections = []
      }
    },
    different_to_median(index){
      return index != Math.floor(this.section_limit / 2)
    },
    change_principal_flag(){
      this.charge_principal = true
    },
    save_values(){
      this.personalize.colors.principal = this.temp_principal
      this.personalize.colors.secundary = this.temp_secundary
      this.clear_values()
    },
    clear_values(){
      this.temp_principal = ''
      this.temp_secundary = ''
      this.section_limit = 3
      this.principal_section = {}
      this.other_sections = [{},{}]
      this.selected_sections = []
      this.charge_limit = false
      this.charge_principal = false
      this.updateShowModal(false)
    },
    updateColors(){
      this.temp_principal = this.personalize.colors.principal
      this.temp_secundary = this.personalize.colors.secundary
    },
    save_sections(){
      for (var i = 0; i < this.other_sections.length; i++) {
        var temp_obj = this.other_sections[i]
        // var principal_position = this.section_limit == 3 ? 1 : 2
        temp_obj.order = i
        temp_obj.principal = false
        this.selected_sections.push(temp_obj)
      }
      console.log("-------Save Sections");
      this.personalize.principal_sections = this.selected_sections
      console.log("------Principal Sections");
      console.log(this.personalize.principal_sections);
      this.personalize.secondary_sections = this.getDifference(this.section_options, this.selected_sections)
      console.log("------Secondary Sections");
      console.log(this.personalize.secondary_sections);
      console.log("------other_sections");
      console.log(this.other_sections);
    },
    getDifference(array1, array2) {
      return array1.filter(object1 => {
        return !array2.some(object2 => {
          return object1.title === object2.title;
        });
      });
    }
  },
  mounted(){
    this.current_is_instance = this.is_instance
    this.personalize = this.getCompanyPersonalize()
    console.log(">----------------Personalize");
    console.log(this.personalize);
    // if(this.personalize.principal_sections.length > 0){
    //   this.section_limit = this.personalize.principal_sections.length
    //   this.charge_limit = true
    // }

    console.log(">----------------Validation Offers");
    console.log(this.getValidationOffers());
    if(this.getValidationOffers()){
      this.section_options.push({title: 'Ofertas', route: 'ofertas', icon:'offer', order: null, private: false, principal: true})
    }
    console.log(">----------------Validation Rewards");
    console.log(this.getValidationRewards());
    if(this.getValidationRewards()){
      this.section_options.push({title: 'Beneficios', route: 'beneficios', icon:'reward', order: null, private: true, principal: false})
    }
    console.log(">----------------Validation Favorites");
    console.log(this.getValidationFavorites());
    if(this.getValidationFavorites()){
      this.section_options.push({title: 'Favoritos', route: 'favoritos', icon:'reward', order: null, private: true, principal: false})
    }
    console.log(">----------------Validation invitations");
    console.log(this.getValidationInvitations());
    if(this.getValidationInvitations()){
      this.section_options.push({title: 'Invitaciones', route: 'invitaciones', icon:'invitaciones', order: null, private: true, principal: false})
    }
    console.log(">----------------Validation Room Bookings");
    console.log(this.getValidationRoomBookings());
    if(this.getValidationRoomBookings()){
      this.section_options.push({title: 'Reservas', route: 'reservas', icon:'access', order: null, private: true, principal: false})
    }
    console.log(">----------------Validation Locations");
    console.log(this.getValidationLocations());
    if(this.getValidationLocations()){
      this.section_options.push({title: 'Sedes', route: 'sedes', icon:'location', order: null, private: false, principal: false})
    }
    console.log(">----------------Validation News");
    console.log(this.getValidationNews());
    if(this.getValidationNews()){
      this.section_options.push({title: 'Noticias', route: 'noticias', icon:'news', order: null, private: false, principal: false})
    }
    console.log(">----------------Validation Us");
    console.log(this.getValidationUs());
    if(this.getValidationUs()){
      this.section_options.push({title: 'Sobre nosotros', route: 'sobre_nosotros', icon:'sobre-nosotros', order: null, private: false, principal: false})
    }
    console.log(">----------------Validation ContactUs");
    console.log(this.getValidationContactUs());
    if(this.getValidationContactUs()){
      this.section_options.push({title: 'Contáctanos', route: 'contactanos', icon:'contactanos', order: null, private: false, principal: false})
    }
    console.log(">----------------Validation Directory");
    console.log(this.getValidationDirectory());
    if(this.getValidationDirectory()){
      this.section_options.push({title: 'Directorio', route: 'directorio', icon:'news', order: null, private: true, principal: false})
    }
    console.log(">----------------Validation Work With Us");
    console.log(this.getValidationWorkWithUs());
    if(this.getValidationWorkWithUs()){
      this.section_options.push({title: 'Trabaja con nosotros', route: 'trabaja_con_nosotros', icon:'trabaja-con-nosotros', order: null, private: false, principal: false})
    }
    // this.selected_sections = this.getCompanyPersonalize().principal_sections


    console.log("---------------------Personalize sections");
    console.log(this.getCompanyPersonalize());

    if (this.getCompanyPersonalize().principal_sections.length > 0){
      console.log("------------------ Personalize Section Limit");
      // this.section_limit = this.getCompanyPersonalize().principal_sections.length
      console.log(this.section_limit);
      this.charge_limit = true
      console.log(this.charge_limit);
      this.charge_principal = true
      console.log(this.charge_principal);
      for (var i = 0; i < this.getCompanyPersonalize().principal_sections.length; i++) {
        var temp = JSON.parse(this.getCompanyPersonalize().principal_sections[i].replaceAll('=>',': '))
        // if (temp.principal === true){
        //   console.log("------------------ Personalize Current Principal Section");
        //   this.current_principal_section = temp
        //   console.log(this.current_principal_section);
        // } else {
          console.log("------------------ Personalize Current selected Section");
          this.current_selected_sections.push(temp)
          console.log(this.current_selected_sections);
        // }

      }
      // if(this.current_is_instance){
      //   this.current_is_instance = false
      // }
    }else{
      this.current_is_instance = false
    }
    // console.log(this.current_principal_section);
    // console.log(this.current_selected_sections);
  },
  beforeDestroy(){
    this.updateCompanyPersonalize(this.personalize)
    console.log(this.getCompanyPersonalize());
  }
}
</script>

<style lang="css">
</style>

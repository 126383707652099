<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="29.941"
    viewBox="0 0 30 29.941">
    <g transform="translate(0 -0.5)">
      <g transform="translate(0 0.5)">
        <g transform="translate(10.605 0)">
          <path
            fill="#6d17c4"
            d="M185.395,9.289a4.395,4.395,0,1,1,4.395-4.395A4.4,4.4,0,0,1,185.395,9.289Z"
            transform="translate(-181 -0.5)"/>
        </g>
        <g transform="translate(0 18.513)">
          <path
            fill="#6d17c4"
            d="M.587,323.049a4.395,4.395,0,1,1,6,1.608,4.4,4.4,0,0,1-6-1.608Z"
            transform="translate(0 -316.455)"/>
        </g>
        <g transform="translate(21.21 18.513)">
          <path
            fill="#6d17c4"
            d="M364.185,324.658a4.394,4.394,0,1,1,6-1.608A4.393,4.393,0,0,1,364.185,324.658Z"
            transform="translate(-361.987 -316.455)"/>
        </g>
      </g>
      <g transform="translate(1.837 6.226)">
        <path
          fill="#b065ff"
          d="M33.106,108.615l-1.754-.1a13.233,13.233,0,0,1,5.941-10.3l.965,1.469A11.474,11.474,0,0,0,33.106,108.615Z"
          transform="translate(-31.352 -98.223)"/>
      </g>
      <g transform="translate(21.257 6.226)">
        <path
          fill="#b065ff"
          d="M367.939,108.616a11.474,11.474,0,0,0-5.152-8.923l.965-1.469a13.233,13.233,0,0,1,5.941,10.3Z"
          transform="translate(-362.787 -98.224)"/>
      </g>
      <g transform="translate(9.057 27.46)">
        <path
          fill="#b065ff"
          d="M160.517,463.607a13.056,13.056,0,0,1-5.943-1.412l.793-1.569a11.5,11.5,0,0,0,10.3,0l.793,1.569A13.055,13.055,0,0,1,160.517,463.607Z"
          transform="translate(-154.574 -460.626)"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

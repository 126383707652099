<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <path
      fill="#2196f3"
      d="M11.562,0H2.187A2.19,2.19,0,0,0,0,2.187V7.812A2.19,2.19,0,0,0,2.187,10h9.375A2.19,2.19,0,0,0,13.75,7.812V2.187A2.19,2.19,0,0,0,11.562,0Zm0,0"/>
    <path
      fill="#1976d2"
      d="M11.562,213.332H2.187A2.19,2.19,0,0,0,0,215.52v13.125a2.19,2.19,0,0,0,2.187,2.187h9.375a2.19,2.19,0,0,0,2.188-2.187V215.52A2.19,2.19,0,0,0,11.562,213.332Zm0,0"
      transform="translate(0 -200.832)"/>
    <path
      fill="#2196f3"
      d="M288.895,341.332H279.52a2.19,2.19,0,0,0-2.188,2.188v5.625a2.19,2.19,0,0,0,2.188,2.187h9.375a2.19,2.19,0,0,0,2.187-2.187V343.52A2.19,2.19,0,0,0,288.895,341.332Zm0,0"
      transform="translate(-261.082 -321.332)"/>
    <path
      fill="#1976d2"
      d="M288.895,0H279.52a2.19,2.19,0,0,0-2.188,2.187V15.312A2.19,2.19,0,0,0,279.52,17.5h9.375a2.19,2.19,0,0,0,2.187-2.188V2.187A2.19,2.19,0,0,0,288.895,0Zm0,0"
      transform="translate(-261.082)"/>
  </svg>
</template>

<script>
export default {
}
</script>

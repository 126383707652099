<template>
  <article class="modal" v-if="getShowModal()">
    <section class="modal__container">
      <slot></slot>
    </section>
  </article>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
